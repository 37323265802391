import React, { lazy, Suspense } from 'react';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import { Spinner } from '@optimizely/axiom';

import { connect } from 'core/ui/decorators';

import { getters as CurrentLayerGetters } from 'bundles/p13n/modules/current_layer';
import { getters as MetricsManagerModuleGetters } from 'bundles/p13n/modules/metrics_manager';

import { MetricsModal } from './metrics_modal';
import {
  getEditMetricForm,
  saveMetricFromModal,
} from '../metrics_manager_react/subcomponents/metrics_picker_react/utils';
import { getInitialScopeValue, getScopeOptions } from './utils';

const MetricsModalMFE = lazy(() =>
  System.import('METRICS').then(module => ({ default: module.MetricsModal })),
);

@connect(() => ({
  layer: CurrentLayerGetters.layer,
  workingMetricWrappers: MetricsManagerModuleGetters.workingMetricWrappers,
}))
export class MetricsModalWrapper extends React.Component {
  render() {
    const {
      /* Component Props */
      metricWrapper,
      onClose,
      onSave,
      title,
      /* FluxConnector Props */
      layer,
      workingMetricWrappers,
    } = this.props;

    const getMetricFormToEdit = metricWrapper
      ? () => getEditMetricForm(metricWrapper)
      : undefined;

    const saveMetric = metricForm => {
      saveMetricFromModal({
        metricForm,
        metricWrapper,
        workingMetricWrappers,
        layer,
      });
      onSave();
    };

    const initialScopeValue = getInitialScopeValue(layer);
    const getScopeOptionsFromLayer = aggregator =>
      getScopeOptions(aggregator, layer);

    const isMetricsHubEnabled = isFeatureEnabled('metrics_hub');
    const MetricsModalComponent = isMetricsHubEnabled
      ? MetricsModalMFE
      : MetricsModal;

    return (
      <Suspense
        fallback={
          <div
            style={{
              height: '100vh',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            className="oui-dialog__overlay">
            <Spinner />
          </div>
        }>
        <MetricsModalComponent
          getMetricFormToEdit={getMetricFormToEdit}
          getScopeOptions={getScopeOptionsFromLayer}
          initialScopeValue={initialScopeValue}
          onClose={onClose}
          onSave={saveMetric}
          title={title}
        />
      </Suspense>
    );
  }
}

export default { MetricsModalWrapper };
