/**
 * Component to handle user selection of the operators used to insert HTML content.
 *
 * These operators are intended to be used for content insertion with respect to
 * another element, representing the 4 possible locations adjacent to an existing element.
 *
 * For example, if that existing element is #insertSelector:
 *
 *
 *   <!-- BEFORE -->
 *   <div id='insertSelector'>
 *     <!-- PREPEND -->
 *     <span></span>
 *     <!-- APPEND -->
 *   </div>
 *   <!-- AFTER -->
 *
 * @type {Component}
 */
import React from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown } from 'optimizely-oui';

import Capitalize from 'optly/filters/capitalize';
import InsertLocationFilter from 'optly/filters/insert_location';

import constants from './constants';

const items = [
  {
    label: Capitalize(InsertLocationFilter(constants.DOMInsertionType.BEFORE)),
    value: constants.DOMInsertionType.BEFORE,
  },
  {
    label: Capitalize(InsertLocationFilter(constants.DOMInsertionType.AFTER)),
    value: constants.DOMInsertionType.AFTER,
  },
  {
    label: Capitalize(InsertLocationFilter(constants.DOMInsertionType.APPEND)),
    value: constants.DOMInsertionType.APPEND,
  },
  {
    label: Capitalize(InsertLocationFilter(constants.DOMInsertionType.PREPEND)),
    value: constants.DOMInsertionType.PREPEND,
  },
];

export default class InsertOperatorDropdown extends React.Component {
  constructor(props) {
    super(props);
    const { initialOperator } = props;
    this.state = {
      operator: initialOperator || constants.DOMInsertionType.BEFORE,
    };
  }

  static propTypes = {
    initialOperator: PropTypes.string.isRequired,
    onOperatorChangeCallback: PropTypes.func.isRequired,
  };

  handleOperatorChosen(operator) {
    const { onOperatorChangeCallback } = this.props;
    const { operator: operatorFromState } = this.state;
    if (operatorFromState !== operator) {
      this.setState({ operator });
      onOperatorChangeCallback(operator);
    }
  }

  componentDidUpdate(prevProps) {
    const { initialOperator } = this.props;
    if (
      initialOperator !== null &&
      initialOperator !== prevProps.initialOperator
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        operator: initialOperator,
      });
    }
  }

  render() {
    const { operator } = this.state;
    return (
      <div className="push-half--bottom display--block">
        <SelectDropdown
          testSection="insert-operator-dropdown"
          items={items}
          value={operator}
          onChange={type => this.handleOperatorChosen(type)}
        />
      </div>
    );
  }
}
