import keyMirror from 'optly/utils/key_mirror';

export const fieldType = {
  // Special
  SELECTOR: 'selector',

  // Text
  TEXT: 'text',
  MULTI_TEXT: 'multi_text',
  RICH_TEXT: 'rich_text',
  NUMBER: 'number',

  // Code
  HTML: 'html',
  CSS: 'css',

  // Enums
  TOGGLE: 'toggle',
  DROPDOWN: 'dropdown',
  MULTI_SELECT: 'multi_select',

  // Attributes
  IMAGE: 'image',
  COLOR: 'color',
};

export const plugin_type = {
  WIDGET: 'widget',
  EDITOR_PANEL: 'editor_panel',
  EVENT: 'event',
  ANALYTICS_INTEGRATION: 'analytics_integration',
};

export const propertyOptions = {
  [plugin_type.WIDGET]: ['css', 'undo_js', 'apply_js', 'html'],

  [plugin_type.EVENT]: ['attach_js', 'detach_js'],

  [plugin_type.ANALYTICS_EXTENSION]: ['track_layer_decision'],
};

const additionalText = ` * Editor now supports ES6 compliant code. Note that adding ES6
 * specific code to an experiment will break for users running
 * ES5-only browsers as code entered is not transpiled down to ES5.`;

export const defaultCode = {
  APPLY_JS: `/*
${additionalText}
 */

  const utils = window.optimizely.get('utils');

  utils.waitForElement('body')
  .then(function(elem) {
    // Prepend the extension html to the body
    elem.insertAdjacentHTML('afterbegin', extension.$html);
  });
`,
  APPLY_JS_EDGE: `/*
${additionalText}
 */

  const utils = window.optimizelyEdge.get('utils');

  utils.waitForElement('body')
  .then(function(elem) {
    // Prepend the extension html to the body
    elem.insertAdjacentHTML('afterbegin', extension.$html);
  });
`,
  UNDO_JS: `/*
${additionalText}
 */
 
  const extensionElement = document.getElementById('optimizely-extension-' + extension.$instance);
  if (extensionElement) {
    extensionElement.parentElement.removeChild(extensionElement);
  }
`,
  TRACK_LAYER_DECISION: `/*
 * Javascript written in this tab will run every time a user is
 * assigned to an experiment and variation, but before any experiment
 * code runs. This hook is useful for tracking which variations a
 * visitor has been assigned to. Click the help icon for more information.
 * 
${additionalText}
 */
`,
};

// Similar to src/www/frontend/src/js/optly/modules/entity/project/enums.js
const snippetGlobalName = {
  OPTIMIZELY: 'optimizely',
  OPTIMIZELY_EDGE: 'optimizelyEdge',
};

export const applyJsBySnippetGlobal = {
  [snippetGlobalName.OPTIMIZELY]: defaultCode.APPLY_JS,
  [snippetGlobalName.OPTIMIZELY_EDGE]: defaultCode.APPLY_JS_EDGE,
};

export const pluginTypeSettingsOptions = {
  [plugin_type.ANALYTICS_EXTENSION]: {
    enableForNewExperiments: {
      OFF: 'off',
      OPTIONAL: 'optional',
      REQUIRED: 'required',
    },
  },
};

export const displayStatuses = keyMirror({
  ARCHIVED: null,
  DRAFT: null,
  ENABLED: null,
});

export default {
  applyJsBySnippetGlobal,
  defaultCode,
  displayStatuses,
  fieldType,
  plugin_type,
  pluginTypeSettingsOptions,
  propertyOptions,
};
