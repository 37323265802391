/**
 * Asynchronous Timing Component
 *
 * This component is intended to handle choosing the timing of a change to execute,
 * with the options being either synchronous or asynchronous.
 *
 * The expectation is that the parent has implemented a handleChangeTimingChanged
 * method to receive updates about the dropdown value changing.
 *
 * @component
 */
const _ = require('lodash');

const EditorConstants = require('bundles/p13n/modules/editor/constants')
  .default;

const Template = require('./template.html');
const constants = require('./constants');

module.exports = {
  // Don't replace wrapper to allow timing component to be hidden with display: none
  replace: false,

  template: Template,

  data: {
    async: false,
    types: constants.TimingTypes,
    timingDropdownTypes: EditorConstants.TimingDropdownTypes,
  },

  computed: {
    /**
     * Shows the chosen timing value in the dropdown menu.
     *
     * includeTimingLabel is passed in via v-with. It adds the word "timing"
     * after all titles in this dropdown.
     */
    displayText() {
      const timing = this.async
        ? this.types.ASYNCHRONOUS
        : this.types.SYNCHRONOUS;
      return this.includeTimingLabel ? timing + tr(' timing') : timing;
    },

    /**
     * Determines if the timing component is in a sidebar.
     * Appropriate CSS styles are applied.
     */
    isSidebar() {
      return (
        this.instanceIdentifier === this.timingDropdownTypes.ELEMENT_TIMING
      );
    },
  },

  methods: {
    /**
     * Changes component's isAsync value and reports to parent that value
     * has changed as well as ID so parent knows which dropdown is reporting
     */
    handleChange(isAsync) {
      this.async = isAsync;
      if (this.$options.handleChangeTimingChanged) {
        this.$options.handleChangeTimingChanged({
          isAsync,
          instanceIdentifier: this.instanceIdentifier,
        });
      } else {
        this.$parent.handleChangeTimingChanged({
          isAsync,
          instanceIdentifier: this.instanceIdentifier,
        });
      }
    },

    handleUpdate() {
      if (this.change) {
        this.async = _.clone(this.change.async);
      }
    },

    setAsync(async) {
      this.async = async;
    },
  },

  ready() {
    this.handleUpdate();
  },
};
