import {
  ButtonRow,
  Button,
  Dialog,
  Dropdown,
  Link,
  Radio,
  Grid,
  GridCell,
  GridContainer,
  Input,
  Typography,
} from 'optimizely-oui';

import PropTypes from 'prop-types';

import classNames from 'classnames';

import ui from 'core/ui';

import './styles.css';
import React from 'react';

const { Wrapper, Footer } = Dialog;

const sortByProperty = {
  RECENTLY_ADDED: 'Recently added',
  NAME: 'Name',
};

const creatorType = {
  ALL: 'All',
  OPTIMIZELY: 'Optimizely',
  CUSTOM: 'Custom',
};

function byNewestFirst() {
  return (a, b) => {
    const createdAtA = Date.parse(a.created);
    const createdAtB = Date.parse(b.created);
    if (createdAtB < createdAtA) {
      return -1;
    }
    if (createdAtB > createdAtA) {
      return 1;
    }

    return 0;
  };
}

function byAlphabeticalOrder() {
  return (a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  };
}

function sortResults(templates, sortBy) {
  switch (sortBy) {
    case sortByProperty.RECENTLY_ADDED:
      return templates.sort(byNewestFirst());
    case sortByProperty.NAME:
      return templates.sort(byAlphabeticalOrder());
    default:
      return templates;
  }
}

function filterByCreatorType(templates, filterCreatorBy) {
  switch (filterCreatorBy) {
    case creatorType.OPTIMIZELY:
      return templates.filter(template => template.optimizely_template);
    case creatorType.CUSTOM:
      return templates.filter(template => !template.optimizely_template);
    default:
      return templates;
  }
}

function filterBySearch(templates, searchBy) {
  return searchBy
    ? templates.filter(
        template =>
          template.name.toLowerCase().includes(searchBy.toLowerCase()) ||
          template.description.toLowerCase().includes(searchBy.toLowerCase()),
      )
    : templates;
}

export default class SelectTemplate extends React.Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
    goToExtensionsTab: PropTypes.func.isRequired,
    templates: PropTypes.arrayOf(
      PropTypes.shape({
        created: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        experiment_count: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        last_modified: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        optimizely_template: PropTypes.bool.isRequired,
      }),
    ).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      creatorFilterBy: creatorType.ALL,
      searchBy: undefined,
      selectedTemplate: undefined,
      sortBy: sortByProperty.RECENTLY_ADDED,
      templates: sortResults(props.templates, sortByProperty.RECENTLY_ADDED),
    };
  }

  onSearch = input => {
    this.setState({ searchBy: input.target.value }, this.updateResults);
  };

  onFilterByCreator = input => {
    this.setState({ creatorFilterBy: input }, this.updateResults);
  };

  onSort = input => {
    this.setState({ sortBy: input }, this.updateResults);
  };

  updateResults = () => {
    const { templates: templateList } = this.props;
    const { creatorFilterBy, searchBy, sortBy } = this.state;

    let filteredTemplates = filterBySearch(templateList, searchBy);
    filteredTemplates = filterByCreatorType(filteredTemplates, creatorFilterBy);

    const filteredAndSortedTemplates = sortResults(filteredTemplates, sortBy);

    this.setState({ templates: filteredAndSortedTemplates });
  };

  selectTemplate = template => {
    this.setState({ selectedTemplate: template.id });
  };

  submit = () => {
    const { callback, templates } = this.props;
    const { selectedTemplate } = this.state;

    callback(templates.filter(template => template.id === selectedTemplate)[0]);
  };

  render() {
    const { goToExtensionsTab } = this.props;
    const {
      creatorFilterBy,
      selectedTemplate,
      searchBy,
      sortBy,
      templates,
    } = this.state;

    return (
      <Wrapper>
        <GridContainer className="hard--ends">
          <Grid>
            <GridCell large={12} medium={8} small={4} xlarge={12}>
              <Typography tag="h1" type="header1">
                Templates
              </Typography>
              <Typography tag="p" type="body">
                Add custom features to experiences without ongoing developer
                support. You can create new Templates in the{' '}
                <Link onClick={goToExtensionsTab}>Templates tab</Link>.
              </Typography>
            </GridCell>
          </Grid>
        </GridContainer>
        <GridContainer className="hard--ends">
          <Grid>
            <GridCell large={6} medium={4} small={2} xlarge={6}>
              <Input
                isFilter={true}
                id="name"
                value={searchBy}
                onChange={this.onSearch}
                className="search-picker__input"
                max={50}
                maxLength={600}
                min={3}
                placeholder="Filter by name or description"
                type="text"
                hasClearButton={true}
                containerClassName="search-picker__input-container"
                testSection="select-template-search-filter"
              />
            </GridCell>

            <GridCell large={3} medium={2} small={2} xlarge={3}>
              <Dropdown
                style="plain"
                arrowIcon="down"
                isLabelTight={true}
                buttonContent={{
                  label: 'Sort',
                  content: sortBy,
                }}
                testSection="select-template-sort">
                <Dropdown.Contents>
                  {Object.entries(sortByProperty).map((item, index) => {
                    const id = item[0];
                    const displayName = item[1];
                    return (
                      <Dropdown.ListItem
                        key={index}
                        role="option"
                        testSection={`select-template-sort-by-${id}`}>
                        <Dropdown.BlockLink
                          onClick={() => this.onSort(displayName)}
                          testSection={`select-template-sort-by-${id}`}>
                          <Dropdown.BlockLinkText text={displayName} />
                        </Dropdown.BlockLink>
                      </Dropdown.ListItem>
                    );
                  })}
                </Dropdown.Contents>
              </Dropdown>
            </GridCell>

            <GridCell large={2} medium={2} small={2} xlarge={2}>
              <Dropdown
                style="plain"
                arrowIcon="down"
                isLabelTight={true}
                buttonContent={{
                  label: 'Creator',
                  content: creatorFilterBy,
                }}
                testSection="select-template-creator-filter">
                <Dropdown.Contents>
                  {Object.entries(creatorType).map((item, index) => {
                    const id = item[0];
                    const displayName = item[1];
                    return (
                      <Dropdown.ListItem key={index} role="option">
                        <Dropdown.BlockLink
                          onClick={() => this.onFilterByCreator(displayName)}
                          testSection={`select-template-creator-filter-by-${id}`}>
                          <Dropdown.BlockLinkText text={displayName} />
                        </Dropdown.BlockLink>
                      </Dropdown.ListItem>
                    );
                  })}
                </Dropdown.Contents>
              </Dropdown>
            </GridCell>
          </Grid>
        </GridContainer>

        <GridContainer>
          <Grid>
            {templates.length === 0 ? (
              <GridCell large={12} medium={8} small={4} xlarge={12}>
                <Typography
                  tag="p"
                  type="subhead"
                  className="text--center push-quad--ends"
                  data-test-section="template-radio-empty-state-msg">
                  There are no templates available. You can manage templates in
                  the <Link onClick={goToExtensionsTab}>Templates tab</Link>.
                </Typography>
              </GridCell>
            ) : (
              templates.map(template => {
                return (
                  <GridCell
                    large={6}
                    medium={4}
                    small={2}
                    xlarge={6}
                    className={classNames('template-radio', {
                      'template-radio-checked':
                        selectedTemplate === template.id,
                    })}>
                    <Radio
                      defaultChecked={false}
                      isDisabled={false}
                      label={
                        <div className="template-radio-label">
                          <Typography tag="p" type="body" className="flush">
                            {template.name}
                          </Typography>
                          <Typography
                            tag="p"
                            type="caption"
                            className="weight--light flush">
                            {template.description}
                          </Typography>
                        </div>
                      }
                      name={template.id}
                      checked={selectedTemplate === template.id}
                      onChange={() => this.selectTemplate(template)}
                      testSection={`template-radio-${template.id}`}
                    />
                  </GridCell>
                );
              })
            )}
          </Grid>
        </GridContainer>

        <Footer>
          <ButtonRow
            rightGroup={[
              <Button
                key="cancel"
                style="plain"
                testSection="select-template-dialog-cancel"
                onClick={ui.hideDialog}>
                Cancel
              </Button>,
              <Button
                key="submit"
                style="highlight"
                testSection="select-template-dialog-submit"
                disabled={
                  !templates.some(template => template.id === selectedTemplate)
                }
                onClick={this.submit}>
                Use Template
              </Button>,
            ]}
          />
        </Footer>
      </Wrapper>
    );
  }
}
