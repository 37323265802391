const _ = require('lodash');
const flux = require('core/flux');
const Vue = require('vue');
const sanitizeHtml = require('optly/utils/sanitize_html');

const ColorPickerComponent = require('bundles/p13n/components/color_picker');
const ColorPickerConstants = require('bundles/p13n/components/color_picker/constants');
const Editor = require('bundles/p13n/modules/editor');
const ChangeEditorSidebarMixin = require('bundles/p13n/modules/ui/mixins/change_editor_sidebar')
  .default;

const TypographyConstants = require('./constants');
const Template = require('./template.html');

const typographyComponent = {
  template: Template,

  replace: true,

  components: {
    'color-picker': ColorPickerComponent,
  },

  data: {
    fontSize: '',
    fontWeight: '',
    fontColor: '',
    css: {
      fontSizeId: Editor.constants.CSSPropertyNames.FONT_SIZE,
      fontWeightId: Editor.constants.CSSPropertyNames.FONT_WEIGHT,
      fontWeightValues: TypographyConstants.FONT_WEIGHT_VALUES,
    },
  },

  methods: {
    handleChangedColor(color) {
      const currentChangeColor = flux.evaluate(
        Editor.getters.cssValueFromCurrentlyEditingChange('color'),
      );
      const originalColor = flux.evaluate(
        Editor.getters.cssValueFromElement('color'),
      );

      if (color && color !== currentChangeColor && color !== originalColor) {
        Editor.actions.setChangeCSSProperty(
          Editor.constants.CSSPropertyNames.COLOR,
          color,
        );
        Editor.actions.applyCurrentlyEditingChange();
      }
    },

    /**
     * @param {Event} event
     */
    updateChangeCSS(event) {
      const property = event.target.name;
      const value = sanitizeHtml(event.target.value, true);
      const currentChangeValue = flux.evaluate(
        Editor.getters.cssValueFromCurrentlyEditingChange(property),
      );

      if (value !== currentChangeValue) {
        Editor.actions.setChangeCSSProperty(property, value);
        Editor.actions.applyCurrentlyEditingChange();
      }
    },

    /**
     * Handler that catches when the component is (re)initialized or the currentlyEditingChange changes
     */
    initializeComponent() {
      if (!this.currentlyEditingChange) {
        return;
      }

      Vue.nextTick(() => {
        this.fontSize = sanitizeHtml(
          flux.evaluate(
            Editor.getters.cssValueFromCurrentlyEditingChange('font-size'),
          ) || flux.evaluate(Editor.getters.cssValueFromElement('font-size')),
          true,
        );

        this.fontWeight = sanitizeHtml(
          flux.evaluate(
            Editor.getters.cssValueFromCurrentlyEditingChange('font-weight'),
          ) || flux.evaluate(Editor.getters.cssValueFromElement('font-weight')),
          true,
        );

        this.fontColor = sanitizeHtml(
          flux.evaluate(
            Editor.getters.cssValueFromCurrentlyEditingChange('color'),
          ) || flux.evaluate(Editor.getters.cssValueFromElement('color')),
          true,
        );

        if (this.$.textColorPicker) {
          this.$.textColorPicker.$emit(
            ColorPickerConstants.EVENTS.SET_COLOR,
            this.fontColor,
          );
        }
      });
    },
  },

  created() {
    flux.bindVueValues(this, {
      // Required by the ChangeEditorSidebar mixin
      currentlyEditingChange: Editor.getters.currentlyEditingChange,
      activeFrameId: Editor.getters.activeFrameId,
      changeStatusMap: Editor.getters.changeStatusMap(),
    });
  },

  ready() {
    this.initializeComponent();
    this.__unwatchCurrentlyEditingChangeId = flux.observe(
      Editor.getters.currentlyEditingChangeId,
      this.initializeComponent.bind(this),
    );
    this.$.textColorPicker.$on(
      ColorPickerConstants.EVENTS.COLOR_UPDATED,
      this.handleChangedColor.bind(this),
    );
  },

  beforeDestroy() {
    if (this.__unwatchCurrentlyEditingChangeId) {
      this.__unwatchCurrentlyEditingChangeId();
    }
  },
};

module.exports = _.merge({}, ChangeEditorSidebarMixin, typographyComponent);
