import { DialogNew, Button, Link, Spinner } from '@optimizely/axiom';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Immutable from 'optly/immutable';
import SegmentTracking from 'optly/modules/segment';

import { validateMetricForm } from './utils';
import { MetricsForm } from './metrics_form';

const subtitle = (
  <div>
    Custom tracking events allow you to capture and report on visitor actions or
    events.{' '}
    <Link
      href="https://support.optimizely.com/hc/en-us/articles/4410289407885-Overview-of-metrics"
      newWindow={true}>
      Learn more
    </Link>
  </div>
);
export function MetricsModal({
  getMetricFormToEdit,
  getScopeOptions,
  initialScopeValue,
  onClose,
  onSave,
  title,
}) {
  const [metricForm, setMetricForm] = useState({
    type: '',
    name: '',
    event: {},
    winningDirection: '',
    aggregator: '',
    scope: '',
    alias: '',
    combineOperator: 'and',
    conditions: {},
    filterByProperties: false,
    isDraft: true,
    compoundNumerator: null,
    compoundDenominator: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleFormValue = (field, value) => {
    setMetricForm(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSave = () => {
    const errors = validateMetricForm(metricForm);
    if (!isEmpty(errors)) {
      setFormErrors(errors);
      return;
    }
    if (metricForm.type === 'Compound Metric') {
      SegmentTracking.tracking.trackEvent('Compound Metric Created', {
        metricName: metricForm.name,
        numeratorAggregator: metricForm.compoundNumerator?.aggregator,
        denominatorAggregator: metricForm.compoundDenominator?.aggregator,
      });
      SegmentTracking.tracking.trackEvent('Compound Metric Used As Secondary Metric', {
        metricName: metricForm.name,
        numeratorAggregator: metricForm.compoundNumerator?.aggregator,
        denominatorAggregator: metricForm.compoundDenominator?.aggregator,
      });
    }

    onSave(metricForm);
  };

  useEffect(() => {
    if (getMetricFormToEdit) {
      setIsLoading(true);

      getMetricFormToEdit().then(metricFormValues => {
        setMetricForm(form => ({
          ...form,
          ...metricFormValues,
        }));
        setIsLoading(false);
      });
    }
  }, [getMetricFormToEdit]);

  return (
    <DialogNew
      title={title}
      subtitle={subtitle}
      className="dialog-xl-wrapper"
      onClose={onClose}
      footerButtonList={
        isLoading
          ? []
          : [
              <Button key="cancel-button" onClick={onClose}>
                Cancel
              </Button>,
              <Button
                key="save-button"
                style="highlight"
                onClick={handleSave}
                testSection="metrics-modal-save-button">
                Save
              </Button>,
            ]
      }>
      {isLoading ? (
        <div className="flex flex-justified--center">
          <Spinner />
        </div>
      ) : (
        <MetricsForm
          formValues={metricForm}
          onChange={handleFormValue}
          initialScopeValue={initialScopeValue}
          getScopeOptions={getScopeOptions}
          formErrors={formErrors}
          getMetricFormToEdit={getMetricFormToEdit}
        />
      )}
    </DialogNew>
  );
}

MetricsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  workingMetricWrappers: PropTypes.instanceOf(Immutable.List).isRequired,
};

MetricsModal.defaultProps = {
  title: '',
};

export default { MetricsModal };
