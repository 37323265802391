import $ from 'jquery';
import _ from 'lodash';

import url from 'url';

import $window from 'optly/window';
import AjaxUtil from 'optly/utils/ajax';
import deparam from 'optly/utils/deparam';
import flux from 'core/flux';
import regex from 'optly/utils/regex';
import Router from 'core/router';
import Domain from 'optly/modules/domain';
import UserGetters from 'optly/modules/entity/user/getters';

import actionTypes from './action_types';
import getters from './getters';
import { States } from './enums';

/**
 * @return {Object} email from currentUser and two_factor_token (one of which will be null)
 */
function getUserTokenAndEmail() {
  const currentUser = flux.evaluate(UserGetters.currentUser);
  const email = currentUser
    ? currentUser.get('id')
    : flux.evaluate(getters.email);

  return {
    email,
    two_factor_token: flux.evaluate(getters.temporaryTwoFactorToken),
  };
}

/**
 * Called from signin.js
 *
 * @param {String} creds Serialized JSON containing user, pass
 * @return {Deferred}
 */
function login(creds) {
  // eslint-disable-next-line fetch/no-jquery
  return $.ajax({
    type: 'POST',
    url: '/account/signin',
    error: null,
    // This is an older endpoint and needs a url encoded query string, strip the leading ?
    data: url
      .format({
        query: creds,
      })
      .substring(1, this.length),
  });
}

/**
 *
 * @param {String} email User email
 * @return {Deferred}
 */
function preLogin(email) {
  // eslint-disable-next-line fetch/no-jquery
  return $.ajax({
    type: 'POST',
    url: '/opti-id/pre-login',
    error: null,
    data: {
      email,
    },
  });
}

/**
 *
 * @return {Deferred}
 */
function initiateOptiIDLogin(email, continue_to = null) {
  let requestUrl = '/opti-id/initiate-login';
  let separator = '?';
  if (email) {
    requestUrl += `${separator}email=${encodeURIComponent(email)}`;
    separator = '&';
  }

  if (continue_to) {
    requestUrl += `${separator}continue_to=${encodeURIComponent(continue_to)}`;
  }

  // eslint-disable-next-line fetch/no-jquery
  return $.ajax({
    url: requestUrl,
    error: null,
  });
}

/**
 * @param {Boolean} shouldEnroll
 * @return {Deferred}
 */
function twoFactorLogin(shouldEnroll) {
  const data = {
    email: flux.evaluate(getters.email),
    totp_code: flux.evaluate(getters.totpCode),
    two_factor_token: flux.evaluate(getters.temporaryTwoFactorToken),
    should_enroll: shouldEnroll,
  };
  // eslint-disable-next-line fetch/no-jquery
  return $.ajax({
    type: 'POST',
    url: '/account/signin/totp_signin',
    data,
  }).then(response => response);
}

/**
 * @param {String} password
 * @return {Deferred}
 */
function passwordLogin(password) {
  const creds = {
    email: flux.evaluate(getters.email),
    password,
  };
  // eslint-disable-next-line fetch/no-jquery
  return $.ajax({
    type: 'POST',
    url: '/account/signin',
    error: null,
    // This is an older endpoint and needs a url encoded query string, strip the leading ?
    data: url
      .format({
        query: creds,
      })
      .substring(1, this.length),
  });
}

/**
 * Called from signin.js
 *
 * @param {String} creds Serialized JSON containing email
 * @return {Deferred}
 */
function ssoLogin(creds) {
  // eslint-disable-next-line fetch/no-jquery
  return $.ajax({
    type: 'POST',
    url: '/sp_initiated_signin',
    error: null,
    // This is an older endpoint and needs a url encoded query string, strip the leading ?
    data: url
      .format({
        query: creds,
      })
      .substring(1, this.length),
  });
}

/**
 * Makes ajax request to generate a qrcode path which can be used to generate a qrcode.
 * @return {Deferred}
 */
function generateTwoFactorSecret() {
  const data = getUserTokenAndEmail();
  data.password = flux.evaluate(getters.password);
  return AjaxUtil.makeV1AjaxRequest({
    url: '/account/generate_two_factor_secret',
    type: 'POST',
    data,
  }).then(response => response);
}

/**
 * Makes ajax request to generate new backup codes for a user and pass back the backup codes and a success message.
 * @return {Deferred}
 */
function getBackupCodes() {
  const data = {
    email: flux.evaluate(getters.email),
    password: flux.evaluate(getters.password),
  };

  return AjaxUtil.makeV1AjaxRequest({
    url: '/account/reset_two_factor_backup_codes',
    type: 'POST',
    data,
  }).then(response => response);
}

/**
 * Set the TOTP code into the flux datastore
 * @param: {String} code
 */
function setTOTPCode(code) {
  flux.dispatch(actionTypes.SET_TOTP_CODE, {
    totpCode: code,
  });
}

function setPassword(password) {
  flux.dispatch(actionTypes.SET_PASSWORD, {
    password,
  });
}

function setEmail(email) {
  flux.dispatch(actionTypes.SET_EMAIL, {
    email,
  });
}

/**
 * Save the metadata that POST /account/signin most recently returned.
 */
function setLastSuccessfulAuth(value) {
  flux.dispatch(actionTypes.SET_LAST_SUCCESSFUL_AUTH, {
    data: value,
  });
}

/**
 * Update the temp 2fa secret for 2fa verification endpoint.
 * This came to solve the security issue CJS-6133.
 */
function setTemp2faSecret(value) {
  flux.dispatch(actionTypes.SET_TEMP_2FA_SECRET, {
    data: value,
  });
}
/**
 * Save the csrf_token that POST /account/signin or
 * POST /account/signin/totp_signin most recently returned.
 */
function setCSRFToken(value) {
  flux.dispatch(actionTypes.SET_CSRF_TOKEN, {
    csrfToken: value,
  });
}

/**
 * For advanceAuthFlow; see below.
 */
function setNextState(value) {
  flux.dispatch(actionTypes.SET_NEXT_STATE, {
    nextState: value,
  });
}

/**
 * Makes ajax request to enroll or disenroll user in 2fa.
 * @return boolean (true/false on success)
 */
// eslint-disable-next-line camelcase
function enrollUserTwoFactor(shouldEnroll) {
  const data = {
    should_enroll: shouldEnroll,
    totp_code: flux.evaluate(getters.totpCode),
    password: flux.evaluate(getters.password),
  };

  // TODO(Dana): Use promise fail function instead of error (gist, need test: https://gist.github.com/ladyd252/2db818945195417c7048)
  return AjaxUtil.makeV1AjaxRequest({
    url: '/account/enroll_user_two_factor',
    type: 'POST',
    data,
  }).then(response => {
    // consume password in datastore
    if (response.succeeded) {
      setPassword(null);
    }
    return response;
  });
}

/**
 * Makes ajax request to validate user's password is correct.
 * @return true or error message.
 */
function validatePassword(password) {
  return AjaxUtil.makeV1AjaxRequest({
    url: '/account/verify_password',
    type: 'POST',
    data: {
      password,
    },
  }).then(response => response);
}

function enrollAccountTwoFactor(shouldEnroll) {
  const data = {
    should_enroll: shouldEnroll,
    password: flux.evaluate(getters.password),
  };
  return AjaxUtil.makeV1AjaxRequest({
    url: '/account/enroll_account_two_factor',
    type: 'POST',
    data,
  }).then(response => response);
}

/**
 * `continue_to` is a query param used to redirect the client to a specific
 * path on the current domain following authentication.
 *
 * This function navigates (with a full page refresh) to the value of the
 * `continue_to` query param, if one is present. If no query param is present,
 * a default path is followed.
 *
 * @param {String} defaultUrl - The path to follow in the absence of a
 *    `continue_to` param.
 * @param {Object} queryParameters - The query parameters to append to the URL
 * @param {String} continueTo - The path or fully qualified URL to follow regardless of defaultUrl and queryParameters.
 */
function handleContinueToUrl(
  defaultUrl = '/projects',
  queryParameters,
  continueTo,
) {
  // Get the current query string, strip '?' from query string
  const currentQueryString = Router.windowLocationSearch().substring(1);

  // Parse the current query parameters from the query string.
  // (Mobile JS differs from desktop JS in the function used to retrieve querystring params)
  const currentQueryParams = $.deparam
    ? $.deparam(currentQueryString)
    : deparam.deparam(currentQueryString);

  // Determine if the destination URL is overwritten by the 'continue_to' query parameter.
  // If we weren't supplied one, but the server provided a continue_to in the response, follow that.
  let overrideUrl;
  if ('continue_to' in currentQueryParams) {
    overrideUrl = currentQueryParams.continue_to;
  } else if (continueTo) {
    overrideUrl = continueTo;
  }

  // Determine the final destination URL to continue to
  let destinationUrl;
  if (typeof overrideUrl !== 'undefined' && Domain.check(overrideUrl)) {
    const overrideHostname = Domain.getLocation(overrideUrl).hostname;
    const isRelativePath =
      !overrideHostname || overrideHostname === $window.location.hostname;

    if (isRelativePath || regex.url.test(overrideUrl)) {
      destinationUrl = overrideUrl;
    }
  } else {
    destinationUrl = defaultUrl;
  }

  // Continue to the destination
  if (typeof destinationUrl !== 'undefined') {
    const pathFormatSpecifier = url.parse(destinationUrl);
    if (queryParameters) {
      pathFormatSpecifier.query = _.merge(
        {},
        pathFormatSpecifier.query,
        queryParameters,
      );
    }

    Router.windowNavigate(url.format(pathFormatSpecifier));
  }
}

/**
 * The many flows through authentication constitute a state machine.
 * Is 2FA enabled? Enrolling or verifying? Is the password expired?
 * This function advances the browser through the auth flow; call it
 * after the user completes auth-related prompts (e.g., email/password
 * authentication, 2FA enrollment or verification, etc.).
 *
 * There is a state 'nextState' in the flux store, which has the semantics
 * 'the next state to be reached, once the user finishes the current step
 * of auth flow'. This convention allows the entirety of the state machine
 * to be described in this function, so the auth flow is simple to understand
 * and maintain from this function alone.
 *
 * This function should be called when the nextState state is reached.
 * It reads the [assumed just-completed] state from the flux
 * store, advances nextState and sends the browser to
 * the page upon whose completion that new state will be reached.
 *
 * @example
 *     initial nextState: EMAIL_PASSWORD_VERIFIED (entering email/pass is the first user action)
 *     User fills in their credentials, POST /signin -> 200 OK, callback calls this function
 *     This function reads EMAIL_PASSWORD_VERIFIED from flux, checks whether an account has 2FA
 *       obligations or not; if yes, for example, then it updates nextState to HAS_SESSION and
 *       routes the user to the relevant 2FA flow (either enrollment or verification)
 */
const advanceAuthFlow = function() {
  const currentState = flux.evaluate(getters.nextState);
  const lastSuccessfulAuth = flux.evaluate(getters.lastSuccessfulAuth);
  const passwordExpired = flux.evaluate(getters.passwordExpired);
  const postSignInPath = flux.evaluate(getters.postSignInPath);
  const isFirstSignin = flux.evaluate(getters.isFirstSignin);
  const queryParams = isFirstSignin ? { is_first_signin: isFirstSignin } : {};

  switch (currentState) {
    case States.EMAIL_PASSWORD_VERIFIED:
      if (lastSuccessfulAuth.get('requires_password')) {
        // don't advance state machine
        const verifyPath = '/signin/password';
        // Preserve any query string currently in the address bar
        // To preserve `continue_to` query param; possibly others
        Router.go(verifyPath + $window.location.search);
      } else if (!lastSuccessfulAuth.get('two_factor_token')) {
        // Non-2FA
        setNextState(States.HAS_SESSION);
        advanceAuthFlow();
      } else {
        // 2FA
        let verifyPath;

        if (lastSuccessfulAuth.get('two_factor_enrolled')) {
          // User has 2-step verification enabled and needs to enter their TOTP code.
          verifyPath = '/signin/verify';
        } else {
          // User does not have 2-step verification enabled and must configure it.
          verifyPath = '/signin/configure';
        }

        // Preserve any query string currently in the address bar
        // To preserve `continue_to` query param; possibly others
        Router.go(verifyPath + $window.location.search);

        setNextState(States.HAS_SESSION);
      }
      break;

    case States.HAS_SESSION:
      if (passwordExpired) {
        Router.go(`/password_expired${$window.location.search}`);
        setNextState(States.READY);
      } else {
        setNextState(States.READY);
        advanceAuthFlow();
      }
      break;

    case States.READY:
      handleContinueToUrl(
        postSignInPath,
        queryParams,
        lastSuccessfulAuth.get('continue_to'),
      );
      break;

    default:
      throw new Error('Auth flow entered invalid state!');
  }
};

export {
  advanceAuthFlow,
  enrollUserTwoFactor,
  enrollAccountTwoFactor,
  generateTwoFactorSecret,
  getBackupCodes,
  handleContinueToUrl,
  login,
  preLogin,
  initiateOptiIDLogin,
  setCSRFToken,
  setLastSuccessfulAuth,
  setTemp2faSecret,
  setEmail,
  setPassword,
  setNextState,
  ssoLogin,
  setTOTPCode,
  twoFactorLogin,
  validatePassword,
  passwordLogin,
};

export default {
  advanceAuthFlow,
  enrollUserTwoFactor,
  enrollAccountTwoFactor,
  generateTwoFactorSecret,
  getBackupCodes,
  handleContinueToUrl,
  login,
  preLogin,
  initiateOptiIDLogin,
  setCSRFToken,
  setLastSuccessfulAuth,
  setTemp2faSecret,
  setEmail,
  setPassword,
  setNextState,
  ssoLogin,
  setTOTPCode,
  twoFactorLogin,
  validatePassword,
  passwordLogin,
};
