import _ from 'lodash';

import { toImmutable } from 'optly/immutable';

import {
  CodeMirror as CodeMirrorMixin,
  ChangeEditorSidebar as ChangeEditorSidebarMixin,
} from 'bundles/p13n/modules/ui/mixins';
import LayerEnums from 'optly/modules/entity/layer/enums';
import UrlHelper from 'optly/services/url_helper';
import LayerExperimentActions from 'optly/modules/entity/layer_experiment/actions';

import flux from 'core/flux';
import ui from 'core/ui';
import Router from 'core/router';

import EditorActions from 'bundles/p13n/modules/editor/actions';
import EditorGetters from 'bundles/p13n/modules/editor/getters';
import CustomCodeActions from 'bundles/p13n/modules/custom_code/actions';

import { actions as ExperimentSectionActions } from 'optly/modules/entity/experiment_section';
import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import CustomCodeGetters from 'bundles/p13n/modules/custom_code/getters';
import LayerExperimentHumanReadable from 'optly/modules/entity/layer_experiment/human_readable';
import P13NUIActions from 'bundles/p13n/modules/ui/actions';

import LayerExperimentFn from 'optly/modules/entity/layer_experiment/fns';

import SidebarHeaderCopyVariations from '../sidebar_header_content_suggestions';
import CreateVariationDialog from './new_variation_dialog';
import Template from './template.html';
import CreateVariationFeedback from './Feedback';

export const ChangeContentSuggestionsSidebar = {
  replace: true,

  componentId: 'p13n-change-content-suggestions',

  components: {
    'sidebar-header': SidebarHeaderCopyVariations,
  },

  template: Template,

  data: {
    contentSuggestions: [],
    initialVariationValue: '',
    contentSuggestionsError: null,
    contentSuggestionsErrorMessage: '',
    isLoading: false,
    isCreatingVariation: false,
  },

  methods: {
    updateHTML(text) {
      if (!this.isCreatingVariation) {
        this.setAttribute('html', text);
      }
    },

    createDefaultChange() {
      P13NUIActions.confirmNavigation(
        this.hasDirtyCustomCode,
        LayerExperimentHumanReadable.CHANGE_TYPES.custom_code,
        () => {
          CustomCodeActions.showCustomCodePanel(false);
          P13NUIActions.showChangeEditorSidebar(true);
        },
      );
    },

    likeSuggest(suggestionsText) {
      this.contentSuggestions = this.contentSuggestions.map(suggestion => {
        if (suggestion.text === suggestionsText) {
          return {
            ...suggestion,
            like: !suggestion.like,
            dislike: false,
          };
        }

        return suggestion;
      });
    },

    dislikeSuggest(suggestionsText) {
      this.contentSuggestions = this.contentSuggestions.map(suggestion => {
        if (suggestion.text === suggestionsText) {
          return {
            ...suggestion,
            dislike: !suggestion.dislike,
            like: false,
          };
        }

        return suggestion;
      });
    },

    getFeedbackText(contentSuggestions, field) {
      return contentSuggestions
        .filter(suggestion => suggestion[field])
        .map(suggestion => suggestion.text);
    },

    getFeedback(contentSuggestions) {
      return {
        include: this.getFeedbackText(contentSuggestions, 'like'),
        exclude: this.getFeedbackText(contentSuggestions, 'dislike'),
      };
    },

    async getContentSuggestions() {
      this.isLoading = true;
      if (this.contentSuggestionsError !== null) {
        this.contentSuggestionsError = null;
      }
      const contentSuggestions = await EditorActions.getContentSuggestions({
        layer_experiment_id: this.experimentId,
        variation_id: this.selectedVariationId,
        text: this.initialVariationValue,
        feedback: this.getFeedback(this.contentSuggestions),
      });
      this.isLoading = false;

      if (contentSuggestions.error) {
        this.contentSuggestionsError = contentSuggestions.error;
        this.contentSuggestionsErrorMessage = contentSuggestions.errorMessage;
      }

      if (contentSuggestions.succeeded) {
        this.contentSuggestions = contentSuggestions.variations.map(
          suggestion => ({
            text: suggestion,
            like: false,
            dislike: false,
          }),
        );
      }
    },

    toggleIsCreatingVariation() {
      this.isCreatingVariation = !this.isCreatingVariation;
    },

    getVariationUrl(variationId) {
      if (this.currentLayer.type === LayerEnums.type.PERSONALIZATION) {
        return UrlHelper.campaignEditor(
          this.experiment.project_id,
          this.experiment.layer_id,
          this.experiment.id,
          variationId,
        );
      }

      if (this.currentLayer.type === LayerEnums.type.MULTIVARIATE) {
        return UrlHelper.mvtExperimentEditor(
          this.experiment.project_id,
          this.experiment.layer_experiment_id,
          this.experiment.id,
          variationId,
        );
      }

      return UrlHelper.experimentEditor(
        this.experiment.project_id,
        this.experiment.id,
        variationId,
      );
    },

    redirectToNewVariation(variationId) {
      Router.windowNavigate(this.getVariationUrl(variationId));
    },

    showFeedback({ type, message, variationId }) {
      ui.renderReactComponent(this, {
        component: CreateVariationFeedback,
        el: this.$$.feedback,
        props: {
          message,
          linkText: 'View',
          linkUrl: variationId && this.getVariationUrl(variationId),
          open: true,
          type,
        },
      });
    },

    deleteVariation(variationId) {
      if (this.currentLayer.type === LayerEnums.type.MULTIVARIATE) {
        ExperimentSectionActions.deleteVariationById(
          toImmutable(this.experiment),
          variationId,
        );
        return;
      }

      LayerExperimentActions.deleteVariationById(
        this.experiment.id,
        variationId,
      );
    },

    createNewVariation(textChange) {
      this.toggleIsCreatingVariation();

      ui.showDialog({
        component: CreateVariationDialog,
        data: {
          experiment: this.experiment,
          textChange,
          toggleIsCreatingVariation: this.toggleIsCreatingVariation.bind(this),
          showFeedback: this.showFeedback.bind(this),
          deleteVariation: this.deleteVariation.bind(this),
          redirectToNewVariation: this.redirectToNewVariation.bind(this),
          suggestedNewVariationName: LayerExperimentFn.suggestedNewVariationName(
            toImmutable(this.experiment.variations),
          ),
          currentLayerType: this.currentLayer.type,
          isMultivariate:
            this.currentLayer.type === LayerEnums.type.MULTIVARIATE,
        },
      });
    },
  },

  created() {
    flux.bindVueValues(this, {
      hasDirtyCustomCode: CustomCodeGetters.hasDirtyCustomCode,
      selectedVariationId: EditorGetters.selectedVariationId,
      experimentId: EditorGetters.currentLayerExperimentOrSectionId,
      experiment: EditorGetters.currentLayerExperimentOrSection,
      currentLayer: CurrentLayerGetters.layer,
    });
  },

  ready() {
    this.getContentSuggestions();
  },
};

export default _.merge(
  {},
  CodeMirrorMixin,
  ChangeEditorSidebarMixin,
  ChangeContentSuggestionsSidebar,
);
