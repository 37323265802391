import $ from 'jquery';
import _ from 'lodash';

import { getFeatureVariable, track } from '@optimizely/js-sdk-lab/src/actions';

import cloneDeep from 'optly/clone_deep';
import flux from 'core/flux';
import { isImmutable } from 'optly/immutable';
import showError from 'core/ui/methods/show_error';
import ChampagneEnums from 'optly/modules/optimizely_champagne/enums';
import { userIdForFeature } from 'optly/modules/optimizely_champagne/getters';
import RestApi from 'optly/modules/rest_api';

import * as enums from './enums';
import fns from './fns';
import getters from './getters';

import entityDef from './entity_definition';

const baseEntityActions = RestApi.createEntityActions(entityDef);
const actionEntityActions = RestApi.createEntityActions(
  Object.assign({}, entityDef, {
    isRelationshipEntity: true,

    /* Denotes this is a subresource of layer_experiment per the parents below. */
    isSubresource: true,

    parents: [
      {
        entity: 'layer_experiments',
        key: 'id',
      },
      {
        entity: 'variations',
        key: 'variation_id',
      },
      {
        entity: 'views',
        entityName: 'actions',
        key: 'view_id',
      },
    ],
  }),
);

const MAX_ENTITIES_PER_API_REQUEST = 40;

/**
 * flatten an array that has at most one level of nested array elements
 */
function shallowFlatten(arrayOfArrays) {
  return arrayOfArrays.reduce((acc, elem) => acc.concat(elem), []);
}

/**
 * look for experiment IDs within the layer decision metadata experiment priorities -
 * returns undefined if not present.
 */
function findExperimentIds(layer) {
  let experimentIds;
  if (
    layer.decision_metadata &&
    layer.decision_metadata.experiment_priorities
  ) {
    experimentIds = shallowFlatten(
      layer.decision_metadata.experiment_priorities,
    );
  }

  return experimentIds;
}

const actions = {
  ...baseEntityActions,

  /**
   * Saves an action after pruning the status field from all changes.
   * @param {Objec} action
   * @return {Deferred}
   */
  cleanAndSaveAction(action, ...args) {
    action.changes.forEach(c => {
      delete c.status;
    });
    return actionEntityActions.save(action, ...args);
  },

  /**
   * Overwrite the default delete and do a soft delete instead
   * @param {LayerExperiment} layerExperiment
   * @return {Deferred}
   */
  delete(layerExperiment) {
    return actions.archive(layerExperiment);
  },

  /**
   * Pauses a layer_experiment
   * @param {LayerExperiment} layerExperiment
   * @return {Deferred}
   */
  pause(layerExperiment) {
    return actions.save({
      id: layerExperiment.id,
      status: enums.status.PAUSED,
      is_launched: false,
    });
  },

  /**
   * Launches a layer_experiment
   * @param {LayerExperiment} layerExperiment
   * @return {Deferred}
   */
  launch(layerExperiment) {
    return actions.save({
      id: layerExperiment.id,
      is_launched: true,
    });
  },

  /**
   * Unpauses a layer_experiment
   * @param {LayerExperiment} layerExperiment
   * @return {Deferred}
   */
  unpause(layerExperiment) {
    return actions.save({
      id: layerExperiment.id,
      status: enums.status.ACTIVE,
      is_launched: false,
    });
  },

  /**
   * Archives an layer_experiment
   * @param {LayerExperiment} layerExperiment
   * @return {Deferred}
   */
  archive(layerExperiment) {
    return actions.save({
      id: layerExperiment.id,
      status: enums.status.ARCHIVED,
      is_launched: false,
    });
  },

  /**
   * Unarchives an layer_experiment
   * @param {LayerExperiment} layerExperiment
   * @return {Deferred}
   */
  unarchive(layerExperiment) {
    return actions.save({
      id: layerExperiment.id,
      status: enums.status.PAUSED,
      is_launched: false,
    });
  },

  /**
   * Saves a layer_experiment
   * @param {LayerExperiment} layerExperiment
   * @param {Object} options
   * @param {Boolean} options.useAudienceConditionsJSON
   *  If true, the audience_conditions_json field (computed in layer_experiment/entity_definition.js) should be used
   *  to compute audience_conditions and audience_ids. Otherwise, audience_conditions and audience_ids will be used
   * @return {Deferred}
   */
  save(layerExperiment, options = { useAudienceConditionsJSON: false }) {
    const { useAudienceConditionsJSON } = options;
    let exp = layerExperiment;
    exp = fns.cleanActionsJSON(exp);
    // TODO(APPX-34) Move to entity_definition.serialize for "audience_conditions" when all LayerExperiment Audiences builders can use rich JSON
    exp = fns.cleanAudiencesJSON(exp, {
      useAudienceConditionsJSON: !!useAudienceConditionsJSON,
    });
    return baseEntityActions.save(exp).then(response => {
      /**
       * Modifying and saving layer experiment will potentially update layer data, so we need to invalidate layer summary.
       */
      RestApi.actions.inValidateCacheDataByEntity({
        entity: 'layer_summaries',
        id: exp.layer_id,
      });
      return response;
    });
  },

  /**
   * Creates a new variation
   * @param {String} variationName
   * @param {LayerExperiment} layerExperiment
   */
  createNewVariation(variationName, layerExperiment) {
    const newVariation = {
      name: variationName,
      actions: [],
    };
    layerExperiment.variations.push(newVariation);
    return actions.save(layerExperiment);
  },

  /**
   * Creates a new variation and redistributes traffic
   * @param {String} variationName
   * @param {Object} layerExperiment
   * @returns {Deferred}
   */
  createNewVariationWithRedistributedTraffic(variationName, layerExperiment) {
    const newVariation = {
      name: variationName,
      actions: [],
    };

    const clonedLayerExperiment = cloneDeep(layerExperiment);
    clonedLayerExperiment.variations.push(newVariation);
    clonedLayerExperiment.variations = fns.redistributeWithNewVariationTraffic(
      clonedLayerExperiment.variations,
    );
    return actions.save(clonedLayerExperiment);
  },

  /**
   * Deletes a variation
   * @param {Object} options
   *   {options.variationId} id of the variation to delete
   *   {options.layerExperiment} layerExperiment
   */
  deleteVariation(options) {
    const { variations } = options;
    variations.forEach(variation => {
      if (variation.variation_id === options.variation.variation_id) {
        variations.splice(variations.indexOf(variation), 1);
      }
    });
    options.layerExperiment.variations = fns.redistributeStoppedVariationTraffic(
      variations,
      options.variation.weight,
    );
    return actions.save(options.layerExperiment);
  },

  /**
   * Stops a variation by archiving it and redistributing traffic
   * @param {Number} variationId
   * @param {Object} layerExperiment
   * @param {Array} variations with weights
   * @param {Boolean} saveAsPartialFactorial
   * @returns {Deferred}
   */
  stopVariation(
    variationId,
    layerExperiment,
    variations,
    saveAsPartialFactorial = false,
  ) {
    const layerExperimentToSave = {
      id: layerExperiment.id,
    };
    if (saveAsPartialFactorial) {
      layerExperimentToSave.multivariate_traffic_policy =
        enums.multivariateTrafficPolicies.PARTIAL_FACTORIAL;
    }
    const clonedVariations = cloneDeep(variations);
    let weightToRedistribute = 0;

    clonedVariations.forEach(variation => {
      if (variation.variation_id === variationId) {
        variation.archived = true;
        variation.status = enums.VariationStatus.ARCHIVED;
        weightToRedistribute = variation.weight;
        variation.weight = 0;
      }
    });

    layerExperimentToSave.variations = fns.redistributeStoppedVariationTraffic(
      clonedVariations,
      weightToRedistribute,
    );

    return actions.save(layerExperimentToSave);
  },

  /**
   * Restores a stopped variation by unarchiving and redistributing weight
   * @param {Number} variationId
   * @param {Object} layerExperiment
   * @param {Array} variations with weights
   * @returns {Deferred}
   */
  restoreVariation(variationId, layerExperiment, variations) {
    const clonedVariations = cloneDeep(variations);

    clonedVariations.forEach(variation => {
      if (variation.variation_id === variationId) {
        variation.archived = false;
        variation.status = enums.VariationStatus.ACTIVE;
      }
    });

    layerExperiment.variations = fns.redistributeRestoredVariationTraffic(
      variationId,
      clonedVariations,
    );
    return actions.save(layerExperiment);
  },

  /**
   * Duplicate the changes belonging to sourceVariationId
   * in layer experiment sourceExperiment into destinationVariationId
   * in destinationExperiment
   * associated with  layer experiment destinationExperiment
   * @param {Immutable.Map} sourceExperiment
   * @param {Immutable.Map} destinationExperiment
   * @param {Number} sourceVariationId
   * @param {Number} destinationVariationId
   */
  duplicateVariationActions(
    sourceExperiment,
    destinationExperiment,
    sourceVariationId,
    destinationVariationId,
  ) {
    const sourceViewIdList = fns.getViewIdsInVariation(
      sourceExperiment,
      sourceVariationId,
    );

    // initialize based on current destination state
    let updatedDestinationExperiment = destinationExperiment;

    // update for each viewId
    sourceViewIdList.map(viewId => {
      const changesInSourceView = fns.changesForViewIdAndVariationIdAndExperimentId(
        viewId,
        sourceVariationId,
        sourceExperiment,
      );
      const changesInSourceViewWithNewGuids = fns.cloneChangeList(
        changesInSourceView.toJS(),
      );
      const changesInDestinationView = fns.changesForViewIdAndVariationIdAndExperimentId(
        viewId,
        destinationVariationId,
        destinationExperiment,
      );
      const consolidatedChangesForView = changesInDestinationView.concat(
        changesInSourceViewWithNewGuids,
      );
      updatedDestinationExperiment = fns.addAction(
        viewId,
        destinationVariationId,
        consolidatedChangesForView,
        updatedDestinationExperiment,
        showError,
      );
    });
    return actions.save(updatedDestinationExperiment.toJS());
  },

  /**
   * Duplicates a variation
   * @param {String} variation
   * @param {LayerExperiment} layerExperiment
   */
  duplicateVariation(variation, layerExperiment) {
    const newName = fns.createDuplicateName(layerExperiment, variation.name);
    const newActions = fns.cloneActions(variation.actions);
    const newVariation = {
      name: newName,
      actions: newActions,
    };
    layerExperiment.variations.push(newVariation);
    layerExperiment.variations = fns.redistributeWithNewVariationTraffic(
      layerExperiment.variations,
    );
    return actions.save(layerExperiment);
  },

  /**
   * Duplicate a layer experiment
   *
   * @param {object} layerExperiment Source experiment
   * @returns {Deferred} resolved on completion of api post
   */
  duplicate(layerExperiment) {
    const layerExperimentData = _.pick(layerExperiment, [
      'status',
      'audience_conditions',
      'audience_ids',
      'holdback',
      'layer_id',
      'project_id',
    ]);
    layerExperimentData.variations = _.map(
      layerExperiment.variations,
      variation => ({
        name: variation.name,
        actions: fns.cloneActions(variation.actions),
        archived: !!variation.archived,
        weight: variation.weight,
      }),
    );
    return actions.save(layerExperimentData);
  },

  /**
   *
   */
  restartExperiment(layerExperiment, stopVariation) {
    // TODO(Dana): Rev experiment id so that visitors get rebucketed. Blocked by TWO-211
  },

  /**
   * Rename a variation in an experiment
   * @param {Number} experimentId
   * @param {Number} variationId
   * @param {String} newName
   * @return {Deferred}
   */
  renameVariation(experimentId, variationId, newName) {
    const updatedVariations = flux
      .evaluate(getters.byId(experimentId))
      .get('variations')
      .map(variation => {
        if (variation.get('variation_id') === variationId) {
          return variation.set('name', newName);
        }
        return variation;
      });
    return actions.save({
      id: experimentId,
      variations: updatedVariations.toJS(),
    });
  },

  /**
   * Duplicates a variation given the variation's experiment's ID and the variation's ID
   * @param {Number} experimentId
   * @param {Number} variationId
   * @return {Deferred}
   */
  duplicateVariationById(experimentId, variationId) {
    const experiment = flux.evaluateToJS(getters.byId(experimentId));
    const toDuplicate = _.find(
      experiment.variations,
      variation => variation.variation_id === variationId,
    );
    return actions.duplicateVariation(toDuplicate, experiment);
  },

  /**
   * Delete a variation given the variation's experiment's ID and the variation's ID
   * @param {Number} experimentId
   * @param {Number} variationId
   * @return {Deferred}
   */
  deleteVariationById(experimentId, variationId) {
    const variations = flux
      .evaluate(getters.byId(experimentId))
      .get('variations');
    const removeIndex = variations.findIndex(
      variation => variation.get('variation_id') === variationId,
    );
    const weightOfStoppedVariation = variations.getIn([removeIndex, 'weight']);
    let newVariations = variations.splice(removeIndex, 1);
    newVariations = fns.redistributeStoppedVariationTraffic(
      newVariations.toJS(),
      weightOfStoppedVariation,
    );
    return actions.save({
      id: experimentId,
      variations: newVariations,
    });
  },

  /**
   * Restore a stopped variation given the variation's experiment's ID and the variation's ID
   * @param {Number} experimentId
   * @param {Number} variationId
   * @return {Deferred}
   */
  restoreVariationById(experimentId, variationId) {
    const variations = flux
      .evaluate(getters.byId(experimentId))
      .get('variations');
    const restoreIndex = variations.findIndex(
      variation => variation.get('variation_id') === variationId,
    );
    let newVariations = variations.update(restoreIndex, variation =>
      variation
        .set('archived', false)
        .set('status', enums.VariationStatus.ACTIVE),
    );
    newVariations = fns.redistributeRestoredVariationTraffic(
      variationId,
      newVariations.toJS(),
    );
    return actions.save({
      id: experimentId,
      variations: newVariations,
    });
  },

  // constant wrapper for better testability
  getMultiRequestThreshold() {
    return MAX_ENTITIES_PER_API_REQUEST;
  },

  /**
   * @param {Object} options
   * @param {Number} options.projectId
   * @param {String|Array<String>=} options.policies
   * @param {Boolean} [options.byPage=false] - Whether fetchAllPages should be used instead of fetchAll
   * @param {Boolean=} [options.archived=false] - Whether to fetch archived or non-archived LayerExperiments
   * @return {Deferred|{firstPage: Deferred, allPages: Deferred}}
   */
  fetchAllByStatus({ projectId, policies, byPage = false, archived = false }) {
    const opts = {
      skipEvaluatingCachedData: true,
      excludeFields: 'variations.variable_values',
    };
    const status = archived
      ? [enums.status.ARCHIVED]
      : [enums.status.ACTIVE, enums.status.PAUSED];

    const fetchFilters = {
      project_id: projectId,
      status,
      $order: 'last_modified:desc',
    };

    if (policies) {
      fetchFilters.layer_policy = policies;
    }

    if (!byPage) {
      return this.fetchAll(fetchFilters, opts);
    }

    const {
      FEATURE_KEY,
      VARIABLES,
    } = ChampagneEnums.FEATURES.fetch_all_paginated;
    fetchFilters.$limit =
      getFeatureVariable(FEATURE_KEY, VARIABLES.page_size) ||
      RestApi.constants.DEFAULT_PAGE_SIZE;

    return this.fetchAllPages(fetchFilters, opts);
  },

  /**
   * Fetch the all layer experiment IDs associated with the given layer.
   *
   * @param {Number} layerId
   * @param {Number} projectId
   * @param {Object} filters
   * @returns {Promise} API json formatted response promise
   */
  fetchAllExperimentIds(layerId, projectId, filters = {}) {
    const apiUrl = `/api/v1/projects/${projectId}/layer_experiments`;
    let apiQueryString = '?ids_only=true';
    Object.assign(filters, { layer_id: layerId });
    _.each(filters, (val, name) => {
      const filterValues = Array.isArray(val) ? val : [val];
      _.each(filterValues, arrVal => {
        apiQueryString += `&filter=${name}:${arrVal}`;
      });
    });
    return fetch(
      `${apiUrl}${apiQueryString}`,
      { credentials: 'same-origin' }, // ensures auth/cookies are passed through on request
    ).then(response => {
      if (response.status >= 400) {
        throw new Error('Unexpected error from server');
      }
      return response.json();
    });
  },

  /**
   * Fetch the layer experiments associated with the given layer using a single API request.
   *
   * @param {Number} layerId
   * @param {Number} projectId
   * @param {Boolean} shouldForceFetch
   * @param {object} filters
   * @returns {Deferred} deferred API request
   */
  fetchLayerExperimentsSingleRequest(
    layerId,
    projectId,
    shouldForceFetch = false,
    filters = {},
  ) {
    return actions.fetchAll(
      Object.assign(
        {
          project_id: projectId,
          layer_id: layerId,
        },
        filters,
      ),
      {
        force: shouldForceFetch,
        skipEvaluatingCachedData: true,
      },
    );
  },

  /**
   * Fetch the layer experiments associated with the given layer using multiple API requests.
   *
   * @param {Number} layerId
   * @param {Number} projectId
   * @param {Boolean} shouldForceFetch
   * @param {object} filters
   * @returns {Deferred} wrapper deferred for all API requests issued
   */
  fetchLayerExperimentsMultipleRequests(
    layerId,
    projectId,
    shouldForceFetch = false,
    filters = {},
  ) {
    const experimentIdSubsets = [];
    const experimentDeferredRequests = [];

    const wrapperDeferred = $.Deferred();
    actions
      .fetchAllExperimentIds(layerId, projectId, filters)
      .then(experiments => {
        const allExperimentIds = experiments.map(
          layerExperiment => layerExperiment.id,
        );

        for (
          let index = 0;
          index < allExperimentIds.length;
          index += actions.getMultiRequestThreshold()
        ) {
          experimentIdSubsets.push(
            allExperimentIds.slice(
              index,
              index + actions.getMultiRequestThreshold(),
            ),
          );
        }

        experimentIdSubsets.forEach(experimentIdSubset => {
          experimentDeferredRequests.push(
            actions.fetchAll(
              {
                project_id: projectId,
                id: experimentIdSubset,
              },
              {
                force: shouldForceFetch,
                skipEvaluatingCachedData: true,
              },
            ),
          );
        });

        Promise.all(experimentDeferredRequests).then(() => {
          wrapperDeferred.resolve(shallowFlatten([...arguments])); // eslint-disable-line prefer-rest-params
        });
      });

    return wrapperDeferred.promise();
  },

  /**
   * Fetch the layer experiments associated with the given layer.  If the number of layer experiments
   * exceeds getMultiRequestThreshold(), the request will be split up into multiple requests for
   * better performance.  Note: this gives better performance only due to quirks with GAE/NDB and this
   * optimization should eventually be moved to frontdoor and this code can then be eliminated.  At the time
   * of coding this, frontdoor was not mature enough to switch over to using that API in favor of the internal
   * layer experiments API.
   *
   * @param {Object} layer
   * @param {Boolean} shouldForceFetch
   * @param {object} filters
   * @returns {Deferred} the deferred for all fetched layer experiments
   */
  intelligentFetchAll(layer, shouldForceFetch, filters = {}) {
    layer = isImmutable(layer) ? layer.toJS() : layer;
    // experimentIdsFromLayer is being used as a rough indicator of the number of experiments in the
    // layer to make an API request splitting decision
    const experimentIdsFromLayer = findExperimentIds(layer);

    // split into multiple API requests if exp info found in layer and # of experiments found is above threshold
    const shouldSplitRequests =
      experimentIdsFromLayer &&
      experimentIdsFromLayer.length > actions.getMultiRequestThreshold();
    const fetchExperimentsFunction = shouldSplitRequests
      ? actions.fetchLayerExperimentsMultipleRequests
      : actions.fetchLayerExperimentsSingleRequest;

    // Make sure the status filter being sent is valid.
    const sanitizedFilters = cloneDeep(filters);
    if (sanitizedFilters.status) {
      if (Array.isArray(sanitizedFilters.status)) {
        sanitizedFilters.status = _.filter(sanitizedFilters.status, status =>
          Object.values(enums.status).includes(status),
        );
      } else if (
        !Object.values(enums.status).includes(sanitizedFilters.status)
      ) {
        delete sanitizedFilters.status;
      }
    }

    return fetchExperimentsFunction.call(
      this,
      layer.id,
      layer.project_id,
      shouldForceFetch,
      sanitizedFilters,
    );
  },

  /**
   * Pause an experiment tied to an environment
   * @param {Immutable.Map} layerExperiment
   * @param {String} environmentKey
   * @return {Deferred}
   */
  pauseExperimentWithEnvironment(layerExperiment, environmentKey) {
    return actions.save({
      id: layerExperiment.get('id'),
      environments: { [environmentKey]: { status: 'paused' } },
    });
  },

  /**
   * Start/run an experiment tied to an environment
   * @param {Immutable.Map} layerExperiment
   * @param {String} environmentKey
   * @return {Deferred}
   */
  runExperimentWithEnvironment(layerExperiment, environmentKey) {
    return actions.save({
      id: layerExperiment.get('id'),
      environments: { [environmentKey]: { status: 'running' } },
    });
  },
};

export default actions;
