const flux = require('core/flux');
const PropTypes = require('prop-types');
const React = require('react');
const Immutable = require('optly/immutable').default;
const { Input } = require('optimizely-oui');

const EditorActions = require('bundles/p13n/modules/editor/actions');
const EditorGetters = require('bundles/p13n/modules/editor/getters');

const FieldLabel = require('./field_label');

const Dimensions = props => (
  <div className="flex flex-align--center">
    <div className="flex--1">
      <CssInput
        property="width"
        value={props.widthValue || ''}
        changeStatusMap={props.changeStatusMap}
      />
    </div>
    <div className="flex--1 push-double--left">
      <CssInput
        property="height"
        value={props.heightValue || ''}
        changeStatusMap={props.changeStatusMap}
      />
    </div>
  </div>
);

Dimensions.propTypes = {
  changeStatusMap: PropTypes.instanceOf(Immutable.Map),
  heightValue: PropTypes.string,
  widthValue: PropTypes.string,
};

const onCSSInput = property =>
  function(event) {
    const newValue = event.target.value;
    const storedValue = flux.evaluate(EditorGetters.storedCSSValue(property));
    const elementValue = flux.evaluate(
      EditorGetters.cssValueFromElement(property),
    );

    // Treat a return to stored value (if one exists) or to the elementInfo value
    // as a revert
    if (
      (storedValue !== null && newValue === storedValue) ||
      (storedValue === null && newValue === elementValue)
    ) {
      EditorActions.revertCSSProperty(property);
    } else {
      EditorActions.setChangeCSSProperty(property, newValue);
    }

    EditorActions.applyCurrentlyEditingChange();
  };

const CssInput = props => {
  const changeStatus = props.changeStatusMap.getIn(['css', props.property]);

  return (
    <div>
      <FieldLabel changeStatus={changeStatus} property={props.property} />
      <Input
        type="text"
        value={props.value}
        onChange={onCSSInput(props.property)}
        testSection={`dimensions-${props.property}-input`}
      />
    </div>
  );
};

CssInput.propTypes = {
  changeStatusMap: PropTypes.instanceOf(Immutable.Map),
  property: PropTypes.string,
  value: PropTypes.string,
};

module.exports = Dimensions;
