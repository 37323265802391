import PropTypes from 'prop-types';

import React from 'react';

import { Checkbox, Input } from 'optimizely-oui';

import flux from 'core/flux';

import ui from 'core/ui';
import UrlHelper from 'optly/services/url_helper';

import { getters as CurrentProjectGetters } from 'optly/modules/current_project';
import { actions as TrackClicksChangeActions } from 'bundles/p13n/modules/track_clicks_change';
import WarnLastMetricDialog from 'bundles/p13n/components/dialogs/warn_last_metric';

import FieldLabel from '../field_label';

/**
 * @param {Object} event
 */
const onNameInput = event => {
  TrackClicksChangeActions.setTrackClickEventName(event.target.value);
};

/**
 * @param {Object} event
 */
const onEnabledChange = event => {
  const enabledChangeSuccessful = TrackClicksChangeActions.setTrackClickEventEnabled(
    event.target.checked,
  );
  if (!enabledChangeSuccessful) {
    ui.showReactDialog(WarnLastMetricDialog, null, {
      isOuiDialog: true,
    });
  }
};

const TrackClicks = props => {
  const projectId = flux.evaluateToJS(CurrentProjectGetters.id);
  const pagesUrl = UrlHelper.pagesHome(projectId);
  return (
    <div className="sidenav__section lego-disclose__content">
      <ol className="lego-form-fields push--bottom">
        <li className="lego-form-field__item push-half--bottom">
          <FieldLabel
            changeStatus={props.changeStatus}
            label="Enable Event Tracking"
            revertAction={TrackClicksChangeActions.revertChanges}
            property="track-clicks"
          />
        </li>
        <li className="lego-form-field__item">
          <Checkbox
            checked={props.enabled}
            label="Track clicks on this element"
            onChange={onEnabledChange}
            data-test-section="track-clicks-enabled"
          />
        </li>
        <li className="lego-form-field__item">
          <Input
            label="Name"
            type="text"
            isDisabled={!props.enabled}
            value={props.nameValue}
            onChange={onNameInput}
            testSection="track-clicks-name"
          />
        </li>
      </ol>
      <div
        className="micro line--tight soft--ends"
        data-test-section="track-clicks-help-text">
        This event will be tracked for all variations in this campaign only. To
        add an event on this page that is tracked across your site, go to{' '}
        <a href={pagesUrl}>Pages.</a>
      </div>
    </div>
  );
};

TrackClicks.propTypes = {
  /** String to signify if should show revert button and status class */
  changeStatus: PropTypes.string,
  /** Whether event is being tracked or not */
  enabled: PropTypes.bool.isRequired,
  /**
   * Whether event has been tagged with value variation_specific,
   * signifying that the event is only tracked for this campaign
   */
  showCampaignSpecificEventText: PropTypes.bool,
  /** Value of name for event */
  nameValue: PropTypes.string,
};

export default TrackClicks;
