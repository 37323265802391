import React from 'react';
import PropTypes from 'prop-types';

import { Button, Label, Link, Sheet, Table } from 'optimizely-oui';
import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

import classNames from 'classnames';

import ui from 'core/ui';
import Immutable from 'optly/immutable';

import {
  enums as LayerEnums,
  humanReadable as LayerHumanReadable,
} from 'optly/modules/entity/layer';
import { Features } from 'optly/utils/enums';

import { SortableTableHeader } from 'react_components/sortable_table';
import SelectDropdown from 'react_components/select_dropdown';

import LoadingOverlay from 'react_components/loading_overlay';
import DashboardEntityTable from 'bundles/p13n/components/entity_dashboard/entity_table';
import UrlHelperV2 from 'optly/services/url_helper';

const TABLE_ID = 'ExperimentsUsageDashboard';
class ExperimentUsageTable extends React.Component {
  static propTypes = {
    currentEntityId: PropTypes.number.isRequired,
    currentProjectId: PropTypes.number.isRequired,
    entities: PropTypes.array.isRequired,
    entityType: PropTypes.string.isRequired,
    experimentsUsageAvailable: PropTypes.bool.isRequired,
    layers: PropTypes.instanceOf(Immutable.List).isRequired,
    onEntityChange: PropTypes.func.isRequired,
  };

  // eslint-disable-next-line class-methods-use-this
  renderButtonsInFooter = () => [
    <Button
      testSection="close-button"
      style="highlight"
      key="Done"
      onClick={ui.hideDialog}>
      Done
    </Button>,
  ];

  // eslint-disable-next-line class-methods-use-this
  isRunning = status => status === LayerEnums.status.RUNNING.toLowerCase();

  getStatusClassName = status =>
    classNames({
      'color--good-news': this.isRunning(status),
    });

  renderTableRow = (experiment, index) => {
    const { currentProjectId } = this.props;
    const layerUrl = UrlHelperV2.campaignHome(
      currentProjectId,
      experiment.get('id'),
    );
    return (
      <Table.TR>
        <Table.TD testSection={`usage-experiment${index}-name`}>
          <div>
            <Link testSection="view-edit-button" href={layerUrl}>
              {experiment.get('name')}
            </Link>
            <div className="micro muted">{experiment.get('description')}</div>
          </div>
        </Table.TD>
        <Table.TD testSection={`usage-experiment${index}-type`}>
          {LayerHumanReadable.TEXT_BY_LAYER_TYPE[experiment.get('type')].name}
        </Table.TD>
        <Table.TD testSection={`usage-experiment${index}-status`}>
          <span className={this.getStatusClassName(experiment.get('status'))}>
            {
              LayerHumanReadable.STATUS_BY_ACTUAL_STATUS[
                experiment.get('status')
              ]
            }
          </span>
        </Table.TD>
      </Table.TR>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  renderTableHeader = () => {
    return (
      <tr>
        <SortableTableHeader field="name" type="string">
          {isFeatureEnabled(Features.USE_OPTIMIZELY_TEMPLATES)
            ? 'Optimization'
            : 'Experiment'}{' '}
          Name
        </SortableTableHeader>
        <SortableTableHeader field="type" type="string">
          Type
        </SortableTableHeader>
        <SortableTableHeader field="status" type="string">
          Status
        </SortableTableHeader>
        <Table.TH />
      </tr>
    );
  };

  render() {
    const {
      currentEntityId,
      entityType,
      entities,
      experimentsUsageAvailable,
      layers,
      onEntityChange,
    } = this.props;

    let emptyState = `Experiments for this ${entityType} may be archived. Try another ${entityType}.`;

    if (
      entityType === 'extension' &&
      isFeatureEnabled(Features.USE_OPTIMIZELY_TEMPLATES)
    ) {
      emptyState = `This template is not in an active test or campaign. Try another template.`;
    }

    return (
      <Sheet
        onClose={ui.hideDialog}
        title="Experiment Usage"
        footerButtonList={this.renderButtonsInFooter()}>
        <LoadingOverlay isLoading={!experimentsUsageAvailable}>
          <SelectDropdown
            isFilterable={true}
            testSection="experiment-usage-entity-select"
            items={entities}
            onChange={entity => onEntityChange(entity)}
            value={currentEntityId}
            width={320}
            minDropdownWidth={320}
          />
          <div className="soft-triple--top">
            {layers.size ? (
              <DashboardEntityTable
                tableId={TABLE_ID}
                testSection="experiment-usage-table"
                data={layers}
                renderTableRow={this.renderTableRow}
                renderTableHeader={this.renderTableHeader}
                defaultSortBy={{ field: 'name', type: 'string', dir: 'desc' }}
              />
            ) : (
              <Label testSection="no-experiment-usage-message">
                {emptyState}
              </Label>
            )}
          </div>
        </LoadingOverlay>
      </Sheet>
    );
  }
}

export default ExperimentUsageTable;
