import $ from 'jquery';

import PropTypes from 'prop-types';

import React from 'react';

import { some, cloneDeep, flatten, isFunction } from 'lodash';

import { Button, ButtonRow, Label } from 'optimizely-oui';

import ui from 'core/ui';

import Immutable, { toImmutable } from 'optly/immutable';

import { withBeforeLeave } from 'react_components/before_leave_context';

import CurrentLayerGetters from 'bundles/p13n/modules/current_layer/getters';
import P13NUIActions from 'bundles/p13n/modules/ui/actions';
import PermissionsGetters from 'optly/modules/permissions/getters';
import Layer from 'optly/modules/entity/layer';
import ViewFns from 'optly/modules/entity/view/fns';

import PagesPopover from 'bundles/p13n/components/help_popovers/pages';
import SelectDropdown from 'react_components/select_dropdown';
import { ConfigureViewSmart } from 'bundles/p13n/components/configure_view_smart';

import LoadingOverlay from 'react_components/loading_overlay';

import ComponentModule from '../../component_module';
import SmartUrlTargetingFull from '../url_targeting_full_smart';
import ViewsForLayer from '../views_for_layer';

@withBeforeLeave
class TargetingFull extends React.Component {
  constructor(props) {
    super(props);

    props.setBeforeLeave(this.beforeLeave);

    this.state = {
      /*
       * The set of selected view IDs. Passed to the saveCurrentLayerViews to
       * update the current layer when Save is clicked. Used in the render
       * method to split views into selected & unselected groups.
       */
      selectedViewIds: props.savedViewIds,
      /*
       * Chronological set of views added during the component's
       * lifetime. Used to display the most recent addition in the
       * editor when this component is used as a dialog
       */
      addedViews: Immutable.Set(),
      targetingType: props.targetingType,
      urlTargetingConfig: props.urlTargetingConfig,
      urlTargetingErrors: {},
      isSaving: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.targetingType !== nextProps.targetingType) {
      this.setState({
        targetingType: nextProps.targetingType,
      });
    }
  }

  beforeLeave = () =>
    P13NUIActions.confirmNavigation(this.hasUnsavedChanges(), 'experiment');

  handleTargetingChange = targetingType => {
    this.setState({
      targetingType,
    });
  };

  reset = () => {
    const { savedViewIds, urlTargetingConfig } = this.props;

    this.setState(
      {
        selectedViewIds: savedViewIds,
        addedViews: Immutable.Set(),
        urlTargetingConfig,
      },
      function() {
        if (isFunction(this.resetChildConfig)) {
          this.resetChildConfig();
        }
      },
    );
  };

  hideDialog = () => {
    this.reset();
    ui.hideDialog();
  };

  onSave = () => {
    const { targetingType } = this.state;

    this.setState({
      isSaving: true,
    });

    let saveDef;
    if (targetingType === Layer.constants.TargetingTypes.URL) {
      saveDef = this.saveWithUrlTargeting();
    } else {
      saveDef = this.saveWithSelectedViews();
    }

    saveDef.then(() => {
      this.setState({
        isSaving: false,
      });
    });
  };

  saveWithSelectedViews = () => {
    const { onSave, targetingType, urlTargetingConfig } = this.props;

    const { addedViews, selectedViewIds } = this.state;

    const saveDef = $.Deferred();
    if (selectedViewIds.size === 0) {
      ui.showNotification({
        message: tr('Please choose at least one page.'),
        type: 'error',
      });
      return saveDef.resolve();
    }

    const confirmDef = ComponentModule.actions.confirmSwitchFromURLTargetingToSavedViews(
      targetingType,
      urlTargetingConfig.get('view_id'),
    );

    confirmDef
      .then(() => {
        const { currentLayerId } = this.props;
        Layer.actions
          .updateLayerViews(currentLayerId, selectedViewIds.toJS())
          .then(() => {
            ui.showNotification({
              message: tr('Your changes have been saved.'),
            });
            if (onSave) {
              const mostRecentlyAddedView = addedViews.last()
                ? addedViews.last().toJS()
                : null;
              onSave(mostRecentlyAddedView);
            }
            this.reset();
            saveDef.resolve();
          });
      })
      .fail(() => saveDef.resolve());

    return saveDef;
  };

  saveWithUrlTargeting = () => {
    const { onSave, savedViewIds, targetingType, views } = this.props;

    const { urlTargetingConfig } = this.state;

    const errors = ViewFns.validateSmartView(urlTargetingConfig, false);

    const saveDef = $.Deferred();

    const flatErrors = cloneDeep(errors);
    flatErrors.conditions = flatten(flatErrors.conditions).join('');

    if (some(flatErrors)) {
      this.setState({
        urlTargetingErrors: errors,
      });
      return saveDef.resolve();
    }

    const confirmDef = ComponentModule.actions.confirmSwitchFromSavedViewsToURLTargeting(
      targetingType,
      savedViewIds,
      views,
    );

    confirmDef
      .then(() => {
        const { currentLayerId } = this.props;
        Layer.actions
          .saveOrUpdateLayerWithUrlTargeting(
            currentLayerId,
            urlTargetingConfig.toJS(),
          )
          .then(urlTargetingView => {
            ui.showNotification({
              message: tr('Your changes have been saved.'),
            });
            if (onSave) {
              onSave(urlTargetingView);
            }
            this.reset();
            saveDef.resolve();
          });
      })
      .fail(() => saveDef.resolve());

    return saveDef;
  };

  saveUrlTargetingAsView = () => {
    const { urlTargetingConfig } = this.state;

    const viewFromUrlTargetingConfig = ComponentModule.actions.convertURLTargetingConfigToSmartView(
      urlTargetingConfig,
    );
    ui.showReactDialog(
      ConfigureViewSmart,
      {
        props: {
          onSave: configuredView => {
            if (urlTargetingConfig.get('view_id')) {
              const { currentLayerId } = this.props;
              // If there is a url targeting view id, the view is being transformed from single_use=True to single_use=False
              // This updated the url targeting fields on the backend, so we need to refetch
              if (currentLayerId) {
                Layer.actions.fetch(currentLayerId, true);
              }
            }

            // After it is created, we add the view to the list of selected views, enabling a dirty state
            this.setState(prevState => ({
              selectedViewIds: prevState.selectedViewIds.add(configuredView.id),
              addedViews: prevState.addedViews.add(toImmutable(configuredView)),
              urlTargetingConfig:
                ComponentModule.constants.DEFAULT_URL_TARGETING_CONFIG_SMART,
            }));
          },
          isInDialog: true,
          isStandAlone: true,
          viewConfiguration: toImmutable(viewFromUrlTargetingConfig),
        },
      },
      {
        fullScreen: true,
        dismissOnBack: true,
        isOuiDialog: true,
      },
    );
  };

  updateSelectedViews = (selectedViewIds, addedViews) => {
    this.setState({
      selectedViewIds,
      addedViews,
    });
  };

  updateUrlTargetingConfig = (config, callback) => {
    this.setState(prevState => {
      let errors = Immutable.Map();
      if (prevState.errors) {
        errors = ComponentModule.fns.validateUrlTargetingConfig(config);
      }
      return {
        urlTargetingConfig: prevState.urlTargetingConfig.merge(config),
        urlTargetingErrors: errors,
      };
    }, callback);
  };

  updateConditionErrors = conditionErrors => {
    this.setState({
      urlTargetingErrors: {
        conditions: conditionErrors,
      },
    });
  };

  usePageAsUrlTarget = view => {
    // Filter view to only fields for url targeting, but delete the api_name to ensure uniqueness
    const urlTargetingConfig = view
      .filter((val, key) =>
        Immutable.Set(Layer.enums.URL_TARGETING_FIELDS).has(key),
      )
      .delete('api_name');

    this.setState({
      urlTargetingConfig,
      targetingType: Layer.constants.TargetingTypes.URL,
    });
  };

  renderWithoutUrlTargeting = () => {
    const { canUseUrlTargeting, views } = this.props;

    const { addedViews, selectedViewIds } = this.state;

    return (
      <form>
        <fieldset>
          <ol className="lego-form-fields">
            <li className="lego-form-field__item">
              <h3>
                Pages
                <PagesPopover />
              </h3>
              <p className="push-double--bottom">
                You can choose a single page, or more than one if youre testing
                a funnel or cross-site experience.
              </p>
              <ViewsForLayer
                onChange={this.updateSelectedViews}
                selectedViewIds={selectedViewIds}
                addedViews={addedViews}
                views={views}
                canUseUrlTargeting={canUseUrlTargeting}
              />
            </li>
          </ol>
        </fieldset>
      </form>
    );
  };

  renderWithUrlTargeting = () => {
    const {
      canUseUrlTargeting,
      campaignType,
      isABExperiment,
      views,
    } = this.props;

    const {
      addedViews,
      isSaving,
      selectedViewIds,
      targetingType,
      urlTargetingErrors,
      urlTargetingConfig,
    } = this.state;

    let entityName = isABExperiment ? 'experiment' : 'campaign';
    let verb = 'test';

    if (campaignType === Layer.enums.campaignTypes.MULTIARMED_BANDIT) {
      entityName = 'optimization';
      verb = 'optimize';
    }

    return (
      <LoadingOverlay isLoading={isSaving}>
        <div className="height--1-1">
          <h1>Targeting</h1>
          <p className="push-double--bottom">
            Define where you’d like this to run. Choose to either target by URL
            or select saved pages to {verb} a funnel or cross-site experience.
          </p>
          <Label>Target By</Label>
          <SelectDropdown
            items={ComponentModule.constants.targetingTypeItems}
            value={targetingType}
            onChange={this.handleTargetingChange}
            width="20%"
            testSection="select-targeting-type"
            trackId="2.0-Campaign-Overview-Targeting.Toggle"
          />
          <div className="soft-double--top">
            {targetingType === Layer.constants.TargetingTypes.URL && (
              <SmartUrlTargetingFull
                config={urlTargetingConfig}
                onChange={this.updateUrlTargetingConfig}
                saveUrlTargetingAsView={this.saveUrlTargetingAsView}
                errors={urlTargetingErrors}
                updateConditionErrors={this.updateConditionErrors}
                setRevertHandler={fn => {
                  this.resetChildConfig = fn;
                }}
              />
            )}
            {targetingType === Layer.constants.TargetingTypes.SAVED_PAGES && (
              <ViewsForLayer
                onChange={this.updateSelectedViews}
                selectedViewIds={selectedViewIds}
                addedViews={addedViews}
                views={views}
                usePageAsUrlTarget={this.usePageAsUrlTarget}
                canUseUrlTargeting={canUseUrlTargeting}
              />
            )}
          </div>
        </div>
      </LoadingOverlay>
    );
  };

  hasUnsavedChanges = () => {
    const {
      savedViewIds: propsSavedViewIds,
      urlTargetingConfig: propsUrlTargetingConfig,
    } = this.props;

    const {
      selectedViewIds,
      targetingType,
      urlTargetingConfig: stateUrlTargetingConfig,
    } = this.state;

    const hasUnsavedUrlTargetingChanges = !propsUrlTargetingConfig.equals(
      stateUrlTargetingConfig,
    );
    const hasUnsavedPagesChanges = !propsSavedViewIds.equals(selectedViewIds);

    if (targetingType === Layer.constants.TargetingTypes.URL) {
      return hasUnsavedUrlTargetingChanges;
    }

    return hasUnsavedPagesChanges;
  };

  renderRequiredIndicatorForUrlTargeting = () => {
    const { targetingType } = this.state;

    return (
      targetingType === Layer.constants.TargetingTypes.URL && (
        <div className="oui-sheet__required-indicator cursor--default color--red">
          <span>* Required field</span>
        </div>
      )
    );
  };

  render() {
    const { canUseUrlTargeting, isInDialog, currentLayer } = this.props;

    const { isSaving } = this.state;

    const buttons = [
      isInDialog ? (
        <Button
          key="revert"
          style="plain"
          onClick={this.hideDialog}
          testSection="pages-revert">
          Cancel
        </Button>
      ) : (
        <Button
          key="revert"
          style="plain"
          isDisabled={!this.hasUnsavedChanges() || isSaving}
          onClick={this.reset}
          testSection="pages-revert">
          Revert
        </Button>
      ),
      <Button
        key="save"
        style="highlight"
        isDisabled={
          !this.hasUnsavedChanges() ||
          isSaving ||
          Layer.fns.isLayerReadonly(currentLayer)
        }
        onClick={this.onSave}
        testSection="pages-save">
        Save
      </Button>,
    ];

    return (
      <div className="reading-column" data-test-section="p13n-editor-pages">
        {!canUseUrlTargeting
          ? this.renderWithoutUrlTargeting()
          : this.renderWithUrlTargeting()}
        <div className="lego-form__footer">
          <div className="flex flex-align--center">
            {this.renderRequiredIndicatorForUrlTargeting()}
            <div className="flex--1">
              <ButtonRow rightGroup={buttons} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TargetingFull.propTypes = {
  campaignType: PropTypes.string,
  canUseUrlTargeting: PropTypes.bool,
  currentLayer: PropTypes.instanceOf(Immutable.Map),
  isABExperiment: PropTypes.bool.isRequired,
  isInDialog: PropTypes.bool,
  /**
   * Callback function for post save
   */
  onSave: PropTypes.instanceOf(Function),
  /**
   * These are the IDs of views that are saved in the current layer, used
   * to initialize this component's props, and to reset back to the saved
   * state when Revert is clicked
   */
  savedViewIds: PropTypes.instanceOf(Immutable.Set),
  /**
   * Determines whether url targeting or saved pages view is shown
   */
  targetingType: PropTypes.string,
  /**
   * Url targeting config for the layer
   */
  urlTargetingConfig: PropTypes.instanceOf(Immutable.Map),
  /**
   * All views for the project
   */
  views: PropTypes.instanceOf(Immutable.List),
};

TargetingFull.defaultProps = {
  campaignType: Layer.enums.campaignTypes.SINGLE_EXPERIMENT,
  isInDialog: false,
};

const WrappedTargetingFull = ui.connectGetters(TargetingFull, {
  savedViewIds: ComponentModule.getters.savedViewIds,
  views: ComponentModule.getters.allSavedViews,
  urlTargetingConfig: ComponentModule.getters.urlTargetingConfigSmart,
  targetingType: ComponentModule.getters.targetingType,
  canUseUrlTargeting: PermissionsGetters.canUseUrlTargeting,
  currentLayerId: CurrentLayerGetters.id,
  currentLayer: CurrentLayerGetters.layer,
});

export default WrappedTargetingFull;
