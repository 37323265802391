import PropTypes from 'prop-types';
import React from 'react';
import htmlSanitizer from 'sanitizer';

import { feature } from '@optimizely/js-sdk-lab/src/decorators';
import { OptimizelyFeature } from '@optimizely/react-sdk';
import { ButtonIcon, Table } from 'optimizely-oui';

import Immutable from 'optly/immutable';
import ui from 'core/ui';
import { connect } from 'core/ui/decorators';

import { actions as EventModuleActions } from 'optly/modules/entity/event';
import { 
  enums as LayerEnums,
  fns as LayerFns,
} from 'optly/modules/entity/layer'; 
import { getters as LayerGetters } from 'optly/modules/entity/layer';

import ListDropdown from 'react_components/list_dropdown';
import EventsExperimentUsageDialog from 'bundles/p13n/components/dialogs/events_experiment_usage';

const EXPERIMENTS_USAGE_INDEX = 1;

@connect({
  layers: LayerGetters.entityCache,
})
@feature('usage_inspector_events')
class CustomEventTableRow extends React.Component {
  static propTypes = {
    canArchiveUserEvent: PropTypes.bool.isRequired,
    editEvent: PropTypes.func.isRequired,
    event: PropTypes.instanceOf(Immutable.Map).isRequired,
    experimentsUsageAvailable: PropTypes.bool.isRequired,
    layers: PropTypes.instanceOf(Immutable.Map).isRequired,
  };

  archiveEvent = () => {
    const { event } = this.props;
    EventModuleActions.archive(event.toJS()).then(() => {
      ui.showNotification({
        message: tr(
          'The event <b>{0}</b> has been archived',
          htmlSanitizer.escape(event.get('name')),
        ),
      });
    });
  };

  unarchiveEvent = () => {
    const { event } = this.props;
    EventModuleActions.unarchive(event.toJS()).then(() => {
      ui.showNotification({
        message: tr(
          'The event <b>{0}</b> has been unarchived',
          htmlSanitizer.escape(event.get('name')),
        ),
      });
    });
  };

  onExperimentUsageView = () => {
    const { event } = this.props;
    ui.showReactDialog(
      EventsExperimentUsageDialog,
      {
        props: {
          selectedEventId: event.get('id'),
        },
      },
      {
        isOuiDialog: true,
        fullScreen: false,
        dismissOnBack: true,
      },
    );
  };

  getExperimentsUsage = (eventId, eventType) => {
    const { layers } = this.props;
    const experimentUsage = layers.filter(
      layer =>
        !layer.get('archived') &&
        !(
          LayerFns.hasLayerConcluded(layer) || 
          // checks the "status" field for Flags/FX experiments
          layer.get('status') === LayerEnums.entityStatus.CONCLUDED
        ) &&
        this.getLayerMetricCountforEvent(layer, eventId, eventType) > 0
    );
    return experimentUsage.size;
  };

  getLayerMetricCountforEvent = (layer, eventId, eventType) => {
    const metricsWithEvent = layer
      .get('metrics')
      .filter(
        metric =>
          metric.get('event_type') === eventType &&
          metric.get('event_id') === eventId,
      );
    return metricsWithEvent.size;
  };

  render() {
    const { editEvent, event, canArchiveUserEvent } = this.props;

    const eventId = event.get('id');
    const eventType = event.get('event_type');
    const experimentUsageCount = this.getExperimentsUsage(eventId, eventType);

    let eventName;

    if (event.get('archived')) {
      eventName = (
        <span
          className="link--disabled"
          data-test-section="events-dashboard-custom-edit-button">
          {event.get('name')}
        </span>
      );
    } else {
      eventName = (
        <a
          className="cursor--pointer"
          data-test-section="events-dashboard-custom-edit-button"
          onClick={() => editEvent(eventId)}>
          {event.get('name')}
        </a>
      );
    }

    const dropdownItems = [
      {
        text: tr('Settings'),
        testSection: 'events-dashboard-custom-settings-button',
        onClick: () => editEvent(eventId),
        isVisible: !event.get('archived'),
      },
      {
        text: tr('Archive'),
        testSection: 'events-dashboard-custom-archive-button',
        onClick: this.archiveEvent,
        isVisible: canArchiveUserEvent && !event.get('archived'),
      },
      {
        text: tr('Unarchive'),
        testSection: 'events-dashboard-custom-unarchive-button',
        onClick: this.unarchiveEvent,
        isVisible: canArchiveUserEvent && event.get('archived'),
      },
    ];
    if (this.usage_inspector_events) {
      dropdownItems.splice(EXPERIMENTS_USAGE_INDEX, 0, {
        text: tr('View Experiment Usage'),
        testSection: 'events-dashboard-custom-experiments-button',
        onClick: this.onExperimentUsageView,
      });
    }

    return (
      <Table.TR testSection="custom-event-table-row">
        <Table.TD>
          <div>
            {eventName}
            <div className="muted micro">{event.get('description')}</div>
          </div>
        </Table.TD>
        <Table.TD>
          <span className="nowrap" data-test-section="custom-event-api-name">
            {event.get('api_name')}
          </span>
        </Table.TD>
        <OptimizelyFeature feature="usage_inspector_events">
          {isEnabled =>
            isEnabled && (
              <Table.TD isNumerical={true}>
                <span
                  className="nowrap"
                  data-test-section="custom-event-experiment-count">
                  {experimentUsageCount}
                </span>
              </Table.TD>
            )
          }
        </OptimizelyFeature>
        <Table.TD>
          <span className="nowrap" data-test-section="custom-event-id">
            {event.get('id')}
          </span>
        </Table.TD>
        <Table.TD>
          <span className="nowrap" data-test-section="custom-event-type">
            Custom
          </span>
        </Table.TD>
        <Table.TD isNumerical={true}>
          <ListDropdown
            minWidth="200px"
            activator={
              <ButtonIcon
                iconFill="default"
                iconName="ellipsis-solid"
                size="small"
                style="plain"
                testSection="custom-event-dropdown-button"
              />
            }
            items={dropdownItems}
          />
        </Table.TD>
      </Table.TR>
    );
  }
}

export default CustomEventTableRow;
