import keyMirror from 'optly/utils/key_mirror';
import { type as LayerType } from 'optly/modules/entity/layer/enums';
import { TEXT_BY_LAYER_TYPE as readableLayerType } from 'optly/modules/entity/layer/human_readable';
import { project_types as projectTypes } from 'optly/modules/entity/project/enums';

export const DEFAULT_CHANGES_TO_FETCH = 50;

export const DEFAULT_MAX_API_FILTERS = 125;

export const DEFAULT_PRETTY_DIFF_LINE_CAP = 1000;

export const DEFAULT_SHOULD_PRETTIFY_DIFF = true;

export const ApiFilterType = {
  ALL_ENTITIES: 'all_entities',
  END_TIME: 'end_time',
  ENTITY: 'entity',
  ENTITY_TYPE: 'entity_type',
  ID: 'id',
  PAGE: 'page',
  PER_PAGE: 'per_page',
  PROJECT_ID: 'project_id',
  START_TIME: 'start_time',
};

export const EntityType = {
  attribute: {
    entityType: 'attribute',
    iconName: 'tag',
    readableName: 'Attribute',
    subTypeToReadableNameMap: {},
  },
  audience: {
    entityType: 'audience',
    iconName: 'user-group-thin',
    readableName: 'Audience',
    subTypeToReadableNameMap: {},
  },
  campaign: {
    entityType: 'campaign',
    iconName: 'megaphone-thin',
    readableName: 'Campaign',
    subTypeToReadableNameMap: {},
  },
  environment: {
    entityType: 'environment',
    iconName: 'code',
    newIconName: 'terminal',
    readableName: 'Environment',
    subTypeToReadableNameMap: {},
  },
  event: {
    entityType: 'event',
    iconName: 'monitor-waveform-thin',
    readableName: 'Event',
    subTypeToReadableNameMap: {},
  },
  experiment: {
    entityType: 'experiment',
    iconName: 'flask-thin',
    readableName: 'Experiment',
    subTypeToReadableNameMap: {
      [LayerType.AB]: readableLayerType[LayerType.AB].name,
      [LayerType.FEATURE]: readableLayerType[LayerType.FEATURE].name,
      [LayerType.MULTIARMED_BANDIT]:
        readableLayerType[LayerType.MULTIARMED_BANDIT].name,
      [LayerType.MULTIVARIATE]: readableLayerType[LayerType.MULTIVARIATE].name,
      [LayerType.PERSONALIZATION]: 'Experience',
    },
  },
  extension: {
    entityType: 'extension',
    iconName: 'puzzle-piece',
    readableName: 'Extension',
    subTypeToReadableNameMap: {},
  },
  feature: {
    entityType: 'feature',
    iconName: 'sliders-thin',
    readableName: 'Feature',
    subTypeToReadableNameMap: {},
  },
  flag: {
    entityType: 'flag',
    iconName: 'flag',
    readableName: 'Flag',
    subTypeToReadableNameMap: {},
  },
  group: {
    entityType: 'group',
    iconName: 'megaphone-thin',
    readableName: 'Group',
    subTypeToReadableNameMap: {},
  },
  list_attribute: {
    entityType: 'list_attribute',
    iconName: 'tag',
    readableName: 'List Attribute',
    subTypeToReadableNameMap: {},
  },
  metrics: {
    entityType: 'metrics',
    iconName: 'chart-line',
    readableName: 'Metrics',
    subTypeToReadableNameMap: {},
  },
  section: {
    entityType: 'section',
    iconName: 'ball-pile-thin',
    readableName: 'Multivariate Section',
    subTypeToReadableNameMap: {},
  },
  page: {
    entityType: 'page',
    iconName: 'file',
    readableName: 'Page',
    subTypeToReadableNameMap: {},
  },
  project: {
    entityType: 'project',
    iconName: 'timeline-arrow-thin',
    readableName: 'Project',
    subTypeToReadableNameMap: {},
  },
  rule: {
    entityType: 'rule',
    iconName: '',
    readableName: 'Rule',
    subTypeToReadableNameMap: {},
  },
  ruleset: {
    entityType: 'ruleset',
    iconName: '',
    readableName: 'Ruleset',
    subTypeToReadableNameMap: {},
  },
  tag: {
    entityType: 'tag',
    iconName: 'tag',
    readableName: 'Tag',
    subTypeToReadableNameMap: {},
  },
  variation: {
    entityType: 'variation',
    iconName: 'variations',
    readableName: 'Variation',
    subTypeToReadableNameMap: {},
  },
  variable: {
    entityType: 'variable',
    iconName: 'variables',
    readableName: 'Variable',
    subTypeToReadableNameMap: {},
  },
  permission: {
    entityType: 'permission',
    iconName: 'user-lock',
    readableName: 'Permission',
    subTypeToReadableNameMap: {},
  },
};

export const FormatWarnings = {
  TOO_LARGE: {
    id: 'TOO_LARGE',
    readable: 'This diff is too large to format',
  },
};

export const SegmentEvents = {
  PAGE_VIEW: 'Change History Page View',
  FILTER_APPLIED: 'Change History Filter Applied',
  HIDE_ALL_DETAILS_CLICKED: 'Change History All Details Closed',
  HIDE_DETAILS_CLICKED: 'Change History Details Closed',
  SHOW_ALL_DETAILS_CLICKED: 'Change History All Details Opened',
  SHOW_DETAILS_CLICKED: 'Change History Details Opened',
  SHOW_MORE_CLICKED: 'Change History Show More Clicked',
  GO_TO_PAGE_CLICKED: 'Change History Pagination Control Clicked',
};

export const UiEntityType = {
  // Default type, should be the first item
  any: {
    entityTypes: [],
    isHiddenOnFlags: false,
    projectTypes: [
      projectTypes.WEB,
      projectTypes.FULL_STACK,
      projectTypes.ROLLOUTS,
    ],
    readableName: 'Any',
    type: 'any',
  },
  // Alpha-ordered types
  ab_test: {
    entityTypes: [`${EntityType.experiment.entityType}:${LayerType.AB}`],
    isHiddenOnFlags: true,
    projectTypes: [projectTypes.WEB, projectTypes.FULL_STACK],
    readableName: readableLayerType[LayerType.AB].name,
    type: 'ab_test',
  },
  attribute: {
    entityTypes: [EntityType.attribute.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.WEB, projectTypes.FULL_STACK],
    readableName: EntityType.attribute.readableName,
    type: 'attribute',
  },
  audience: {
    entityTypes: [EntityType.audience.entityType],
    isHiddenOnFlags: false,
    projectTypes: [
      projectTypes.WEB,
      projectTypes.FULL_STACK,
      projectTypes.ROLLOUTS,
    ],
    readableName: EntityType.audience.readableName,
    type: 'audience',
  },
  environment: {
    entityTypes: [EntityType.environment.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.FULL_STACK, projectTypes.ROLLOUTS],
    readableName: EntityType.environment.readableName,
    type: 'environment',
  },
  event: {
    entityTypes: [EntityType.event.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.WEB, projectTypes.FULL_STACK],
    readableName: EntityType.event.readableName,
    type: 'event',
  },
  extension: {
    entityTypes: [EntityType.extension.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.WEB],
    readableName: EntityType.extension.readableName,
    type: 'extension',
  },
  feature: {
    entityTypes: [EntityType.feature.entityType],
    isHiddenOnFlags: true,
    projectTypes: [projectTypes.FULL_STACK, projectTypes.ROLLOUTS],
    readableName: EntityType.feature.readableName,
    type: 'feature',
  },
  feature_test: {
    entityTypes: [`${EntityType.experiment.entityType}:${LayerType.FEATURE}`],
    isHiddenOnFlags: true,
    projectTypes: [projectTypes.FULL_STACK],
    readableName: readableLayerType[LayerType.FEATURE].name,
    type: 'feature_test',
  },
  flag: {
    entityTypes: [EntityType.flag.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.FULL_STACK, projectTypes.ROLLOUTS],
    readableName: EntityType.flag.readableName,
    type: 'flag',
  },
  group: {
    entityTypes: [EntityType.group.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.WEB, projectTypes.FULL_STACK],
    readableName: EntityType.group.readableName,
    type: 'group',
  },
  list_attribute: {
    entityTypes: [EntityType.list_attribute.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.WEB],
    readableName: EntityType.list_attribute.readableName,
    type: 'list_attribute',
  },
  metrics: {
    entityTypes: [EntityType.metrics.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.WEB],
    readableName: EntityType.metrics.readableName,
    useFeature: 'metrics_usability',
    type: 'metrics',
  },
  multiarmed_bandit_test: {
    entityTypes: [
      `${EntityType.experiment.entityType}:${LayerType.MULTIARMED_BANDIT}`,
    ],
    isHiddenOnFlags: true,
    projectTypes: [projectTypes.WEB, projectTypes.FULL_STACK],
    readableName: readableLayerType[LayerType.MULTIARMED_BANDIT].name,
    type: 'multiarmed_bandit_test',
  },
  multivariate_test: {
    entityTypes: [
      `${EntityType.experiment.entityType}:${LayerType.MULTIVARIATE}`,
      EntityType.section.entityType,
    ],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.WEB],
    readableName: readableLayerType[LayerType.MULTIVARIATE].name,
    type: 'multivariate_test',
  },
  page: {
    entityTypes: [EntityType.page.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.WEB],
    readableName: EntityType.page.readableName,
    type: 'page',
  },
  personalization: {
    entityTypes: [
      EntityType.campaign.entityType,
      `${EntityType.experiment.entityType}:${LayerType.PERSONALIZATION}`,
    ],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.WEB],
    readableName: readableLayerType[LayerType.PERSONALIZATION].name,
    type: 'personalization',
  },
  project: {
    entityTypes: [EntityType.project.entityType],
    isHiddenOnFlags: false,
    projectTypes: [
      projectTypes.WEB,
      projectTypes.FULL_STACK,
      projectTypes.ROLLOUTS,
    ],
    readableName: EntityType.project.readableName,
    type: 'project',
  },
  rule: {
    entityTypes: [EntityType.rule.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.FULL_STACK, projectTypes.ROLLOUTS],
    readableName: EntityType.rule.readableName,
    type: 'rule',
  },
  ruleset: {
    entityTypes: [EntityType.ruleset.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.FULL_STACK, projectTypes.ROLLOUTS],
    readableName: EntityType.ruleset.readableName,
    type: 'ruleset',
  },
  // TODO(APPX-605): NOTE - this needs to first be added to the public API, then to change history
  tag: {
    entityTypes: [EntityType.tag.entityType],
    isHiddenOnFlags: false,
    projectTypes: [],
    readableName: EntityType.tag.readableName,
    type: 'tag',
  },
  variation: {
    entityTypes: [EntityType.variation.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.FULL_STACK, projectTypes.ROLLOUTS],
    readableName: EntityType.variation.readableName,
    type: 'variation',
  },
  variable: {
    entityTypes: [EntityType.variable.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.FULL_STACK, projectTypes.ROLLOUTS],
    readableName: EntityType.variable.readableName,
    type: 'variable',
  },
  permission: {
    entityTypes: [EntityType.permission.entityType],
    isHiddenOnFlags: false,
    projectTypes: [projectTypes.FULL_STACK, projectTypes.ROLLOUTS],
    readableName: EntityType.permission.readableName,
    type: 'permission',
  },
};

export const UiFilterType = {
  DATE_RANGE: {
    id: 'DATE_RANGE',
    label: 'Date',
    placeholder: 'Anytime',
    filterName: [ApiFilterType.START_TIME, ApiFilterType.END_TIME],
    projectTypes: [
      projectTypes.WEB,
      projectTypes.FULL_STACK,
      projectTypes.ROLLOUTS,
    ],
  },
  LOG_ID: {
    id: 'LOG_ID',
    label: null,
    placeholder: null,
    filterName: ApiFilterType.ID,
    projectTypes: [
      projectTypes.WEB,
      projectTypes.FULL_STACK,
      projectTypes.ROLLOUTS,
    ],
  },
  SEARCH: {
    id: 'SEARCH',
    label: null,
    placeholder: 'Search by type:ID (ex. page:1234)',
    filterName: ApiFilterType.ENTITY,
    projectTypes: [
      projectTypes.WEB,
      projectTypes.FULL_STACK,
      projectTypes.ROLLOUTS,
    ],
  },
  SOURCE: {
    id: 'SOURCE',
    label: 'Source',
    placeholder: 'Any',
    filterName: 'source',
    values: {
      any: null,
      optimizely: 'api',
      user: 'ui',
    },
    projectTypes: [
      projectTypes.WEB,
      projectTypes.FULL_STACK,
      projectTypes.ROLLOUTS,
    ],
  },
  TYPE: {
    id: 'TYPE',
    label: 'Type',
    placeholder: 'Any',
    filterName: 'type',
    projectTypes: [
      projectTypes.WEB,
      projectTypes.FULL_STACK,
      projectTypes.ROLLOUTS,
    ],
  },
};

export const UiStyles = keyMirror({
  MANAGER: null,
  PROJECT: null,
});

export default {
  ApiFilterType,
  DEFAULT_CHANGES_TO_FETCH,
  DEFAULT_MAX_API_FILTERS,
  DEFAULT_PRETTY_DIFF_LINE_CAP,
  DEFAULT_SHOULD_PRETTIFY_DIFF,
  EntityType,
  FormatWarnings,
  SegmentEvents,
  UiFilterType,
  UiEntityType,
  UiStyles,
};
